import { checkMatricule } from 'shared/matricule';
import { dateTooOld } from 'shared/date-utils';
export const matriculeRule = {
    name: 'matricule',
    rules: {
        getMessage: field => 'The matricule is not valid',
        validate: value => checkMatricule(value)
    }
};
export const dateBirthRule = {
    name: 'dateBirth',
    rules: {
        getMessage: field => 'The person cannot be older than 30 years',
        validate: value => {
            return !value || !dateTooOld(value);
        }
    }
};
export const dictionary = {
    en: {
        messages: {
            email: () => 'The e-mail is not valid',
            date_format: () => 'The date must be in format YYYY-MM-DD',
            required: () => 'The field is required',
            matricule: () => 'The matricule is not valid',
            dateBirth: () => 'The person cannot be older than 30 years',
        }
    },
    fr: {
        messages: {
            email: () => "L'e-mail n'est pas valide",
            date_format: () => 'La date doit être au format AAAA-MM-JJ',
            required: () => 'Ce champ est requis',
            matricule: () => "Le matricule n'est pas valide",
            dateBirth: () => 'La personne ne doit pas avoir plus de 30 ans',
        }
    }
};
export const config = {
    inject: false
};
