var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "grey lighten-4" },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "blue-grey lighten-4 no-print",
          attrs: { app: "", permanent: "", light: "", "hide-overlay": "" },
        },
        [
          _c(
            "v-list",
            { staticClass: "py-1 indigo" },
            [
              _c(
                "v-list-tile",
                {
                  staticClass: "aupair-logo",
                  attrs: { avatar: "", tag: "ul", to: "/" },
                },
                [
                  _c(
                    "v-list-tile-title",
                    { staticClass: "title" },
                    [
                      _c("v-tooltip", { attrs: { debounce: "2000" } }, [
                        _c(
                          "span",
                          {
                            staticClass: "indigo--text text--lighten-4",
                            attrs: { slot: "activator" },
                            slot: "activator",
                          },
                          [_vm._v("Aupair DB")]
                        ),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v("Aupair DB - version " + _vm._s(_vm.version)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-slide-y-transition",
            { attrs: { mode: "out-in" } },
            [_c("router-view", { attrs: { name: "sidebar" } })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-toolbar",
        { staticClass: "indigo no-print", attrs: { app: "", dark: "" } },
        [
          _c(
            "v-toolbar-items",
            { staticClass: "hidden-xs-and-down ml-0 heading-custom" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "btn-dashboard",
                    flat: "",
                    ripple: "",
                    exact: "",
                    to: "/",
                    "active-class": "indigo lighten-2",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.activeTab = 0
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("toolbar.dashboard")) + "\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "btn-search",
                    flat: "",
                    ripple: "",
                    to: "/search",
                    "active-class": "indigo lighten-2",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.activeTab = 1
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("toolbar.search")) + "\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "btn-statistics",
                    flat: "",
                    ripple: "",
                    to: "/statistics",
                    "active-class": "indigo lighten-2",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.activeTab = 2
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("toolbar.statistics")) + "\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-toolbar-items",
            { staticClass: "hidden-xs-and-down mr-0 heading-custom" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "btn-settings",
                    flat: "",
                    ripple: "",
                    to: "/settings",
                    "active-class": "indigo lighten-2",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.activeTab = 3
                    },
                  },
                },
                [_c("v-icon", [_vm._v("settings")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { id: "btn-logout", flat: "", ripple: "" },
                  on: { click: _vm.logout },
                },
                [_c("v-icon", [_vm._v("logout")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { staticClass: "grey lighten-4", attrs: { fluid: "" } },
            [
              _c(
                "v-slide-x-transition",
                { attrs: { mode: "out-in" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-footer",
        {
          staticClass: "indigo no-print",
          attrs: { app: "", inset: "", absolute: "" },
        },
        [
          _c(
            "v-layout",
            { staticClass: "mx-3" },
            [
              _c("span", { staticClass: "white--text" }, [
                _vm._v("© 2017 " + _vm._s(_vm.year)),
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn-toggle",
                {
                  attrs: { mandatory: "" },
                  on: { change: _vm.langChange },
                  model: {
                    value: _vm.langToggle,
                    callback: function ($$v) {
                      _vm.langToggle = $$v
                    },
                    expression: "langToggle",
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "language",
                      attrs: { flat: "", value: "en" },
                    },
                    [_vm._v("\n          EN\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "language",
                      attrs: { flat: "", value: "fr" },
                    },
                    [_vm._v("\n          FR\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("snackbar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }