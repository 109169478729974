var _a;
import map from 'lodash.map';
import Countries from 'shared/all-countries';
import Languages from 'shared/all-languages';
import Nationalities from 'shared/all-nationalities';
let locale = 'fr';
if (typeof window !== 'undefined') {
    locale = (_a = localStorage === null || localStorage === void 0 ? void 0 : localStorage.language) !== null && _a !== void 0 ? _a : 'fr';
}
const stringCompare = (a, b) => String(a.text).localeCompare(b.text);
export const countries = {
    en: map(Countries.en, (text, value) => ({ text, value })).sort(stringCompare),
    fr: map(Countries.fr, (text, value) => ({ text, value })).sort(stringCompare)
};
export const languages = {
    en: map(Languages.en, (text, value) => ({ text, value })).sort(stringCompare),
    fr: map(Languages.fr, (text, value) => ({ text, value })).sort(stringCompare)
};
export const nationalities = {
    en: map(Nationalities.en, (text, value) => ({ text, value })).sort(stringCompare),
    fr: map(Nationalities.fr, (text, value) => ({ text, value })).sort(stringCompare)
};
const getName = function (List, code) {
    if (code === '' || code === undefined) {
        return '';
    }
    if (List[locale][code] === undefined) {
        return 'Unknown';
    }
    return List[locale][code];
};
export const getCountryName = function (code) {
    return getName(Countries, code);
};
export const getLanguageName = function (code) {
    return getName(Languages, code);
};
export const getNationalityName = function (code) {
    return getName(Nationalities, code);
};
export const concatLanguages = function (languages) {
    if (languages) {
        return languages
            .map(language => getLanguageName(language))
            .join(', ');
    }
    return '';
};
export const concatCountries = function (countries) {
    if (countries) {
        return countries
            .map(country => getCountryName(country))
            .join(', ');
    }
    return '';
};
export const concatNationalities = function (nationalities) {
    if (nationalities) {
        return nationalities
            .map(nationality => getNationalityName(nationality))
            .join(', ');
    }
    return '';
};
export default {
    countries,
    languages,
    nationalities,
    getCountryName,
    getLanguageName,
    getNationalityName,
    concatLanguages,
    concatCountries,
    concatNationalities
};
