import { Actions, DocumentActions, NoteActions, ExportActions, StatisticsActions, parachute } from '@/store/Actions';
import { Mutations, NoteMutations, CovenantMutations, QueryMutations } from '@/store/Mutations';
import { defaultAupair } from '@/store/defaults';
import clonedeep from 'lodash.clonedeep';
import axios from '@/backend';
export const mutations = {
    create(state) {
        state.editing = clonedeep(defaultAupair);
    },
    updateInfoSessionsLocal(state, { index, infoSessionUuid }) {
        state.editing.infoSessions[index] = infoSessionUuid;
    },
    setActiveQuery: QueryMutations.setActiveQuery,
    conserveActiveQuery: QueryMutations.conserveActiveQuery,
    ...CovenantMutations,
    ...NoteMutations,
    ...Mutations,
};
export const actions = {
    aupairInfoSessionUpdate: parachute(async ({ commit, state }, { infoSessionNew, infoSessionOld, index }) => {
        console.log('aupairInfoSessionUpdate', state.editing.uuid, index);
        const aupairUuid = state.editing.uuid;
        const updateOldInfoSession = async () => {
            infoSessionOld.aupairs = infoSessionOld.aupairs.filter(a => a.uuid !== aupairUuid);
            await axios.put(`/api/infoSessions/${infoSessionOld.id}`, infoSessionOld);
        };
        const updateNewInfoSession = async () => {
            infoSessionNew.aupairs.push(state.editing);
            await axios.put(`/api/infoSessions/${infoSessionNew.id}`, infoSessionNew);
        };
        let infoSessionUuid;
        if (!infoSessionNew && infoSessionOld) {
            await updateOldInfoSession();
            infoSessionUuid = undefined;
        }
        else if (infoSessionNew && !infoSessionOld) {
            await updateNewInfoSession();
            infoSessionUuid = infoSessionNew.uuid;
        }
        else if (infoSessionNew.uuid !== infoSessionOld.uuid) {
            await updateOldInfoSession();
            await updateNewInfoSession();
            infoSessionUuid = infoSessionNew.uuid;
        }
        commit('updateInfoSessionsLocal', { index, infoSessionUuid });
    }),
    dismissAupair: parachute(async ({ commit, state, dispatch }, { uuid }) => {
        const { data } = await axios.get(`/api/${state.name}/${uuid}`);
        const aupair = data.content;
        aupair.emailReminderSent = 2;
        return axios.put(`/api/${state.name}/${uuid}`, aupair);
    }),
    ...StatisticsActions,
    ...DocumentActions,
    ...ExportActions,
    ...NoteActions,
    ...Actions
};
export const getters = {
    all: (state) => {
        return state.all;
    },
    allAvailable: (state) => {
        return state.allAvailable;
    },
    activeQuery: (state) => {
        return state.activeQuery || (localStorage[state.name] ? JSON.parse(localStorage[state.name]) : undefined);
    },
    editing: (state) => {
        return state.editing;
    },
    statistics: (state) => {
        return state.statistics;
    }
};
export const state = {
    all: [],
    allPaginated: [],
    totalCount: 0,
    allAvailable: {},
    activeQuery: undefined,
    name: 'aupairs',
    editing: undefined,
    deleting: undefined,
    statistics: undefined,
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
