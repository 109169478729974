const Luhn = {
    calculate(strDigits) {
        let sum = 0;
        const parity = strDigits.length % 2;
        for (let i = 0; i < strDigits.length; i++) {
            let value = parseInt(strDigits[i], 10);
            if (i % 2 !== parity) {
                value *= 2;
            }
            if (value > 9) {
                value = (value % 10) + Math.floor(value / 10);
            }
            sum += value;
        }
        return String((10 - (sum % 10)) % 10);
    },
};
const Verhoeff = {
    d: [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
        [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
        [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
        [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
        [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
        [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
        [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
        [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
        [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
    ],
    p: [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
        [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
        [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
        [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
        [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
        [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
        [7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
    ],
    inv: [0, 4, 3, 2, 1, 5, 6, 7, 8, 9],
    invArray(array) {
        if (Object.prototype.toString.call(array) === '[object Number]') {
            array = String(array);
        }
        if (Object.prototype.toString.call(array) === '[object String]') {
            array = array.split('').map(Number);
        }
        return array.reverse();
    },
    generate(array) {
        let c = 0;
        const invertedArray = Verhoeff.invArray(array);
        for (let i = 0; i < invertedArray.length; i++) {
            c = Verhoeff.d[c][Verhoeff.p[((i + 1) % 8)][invertedArray[i]]];
        }
        return Verhoeff.inv[c];
    },
    stringToArray(str) {
        const array = [];
        for (let i = 0; i < str.length; ++i) {
            array.push(parseInt(str[i], 10));
        }
        return array;
    },
    calculate(str) {
        return String(Verhoeff.generate(Verhoeff.stringToArray(str)));
    },
};
export function checkMatricule(value) {
    if (typeof (value) !== 'string' || value.length !== 13 || Number.isNaN(value)) {
        return false;
    }
    const matricule = value.slice(0, 11);
    const checkCodes = Luhn.calculate(matricule) + Verhoeff.calculate(matricule);
    return value === matricule + checkCodes;
}
export function generateFakeMatricule(value) {
    const year = 1800 + Math.floor(Math.random() * 100);
    const month = 1 + Math.floor(Math.random() * 12);
    const day = 1 + Math.floor(Math.random() * 27);
    const rnd = Math.floor(Math.random() * 1000);
    const matricule = year.toString() + (month < 10 ? '0' : '') + month.toString()
        + (day < 10 ? '0' : '') + day.toString()
        + (rnd < 100 ? '0' : '') + (rnd < 10 ? '0' : '') + rnd.toString();
    const checkCodes = `${Luhn.calculate(matricule)}${Verhoeff.calculate(matricule)}`;
    return matricule + checkCodes;
}
