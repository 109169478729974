import { mapMutations, mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters('snackbar', {
            snackbarMessage: 'message',
            snackbarColor: 'color',
            translate: 'translate',
            timeout: 'timeout',
            isOpen: 'isOpen'
        }),
        snackbarIsOpen: {
            get() { return this.isOpen; },
            set() { this.setOpen(); }
        }
    },
    methods: {
        ...mapMutations('snackbar', {
            hideSnackbar: 'hide',
            setOpen: 'setOpen'
        })
    }
};
