import backend from '../backend';
import { EventEmitter } from 'events';
import { Optional, Result } from 'lonad';
import { deleteCookie, getCookie } from '../utils/get-cookie';
import { always, prop, pipe } from 'ramda';
let loggedUser = Optional.None();
const Session = Object.assign(Object.create(EventEmitter.prototype), {
    authenticated() {
        return readTokenFromCookie().valuePresent || readStoredUser().valuePresent;
    },
    getUser() {
        return loggedUser;
    },
    async logout(logoutPageUrl) {
        removeTokenCookie();
        removeUser();
        configureTokenHeader(Optional.None());
        loggedUser = Optional.None();
        if (logoutPageUrl) {
            window.location.href = logoutPageUrl;
        }
        Session.emit('session-closed');
    },
    async refresh() {
        const destroySessionAndReturnFalse = pipe(Session.logout, always(false));
        return Result
            .expect(Optional.first([readTokenFromStoredUser(), readTokenFromCookie()]))
            .expectMap(token => {
            return backend
                .get('/api/auth', { headers: makeAuthorizationHeaders(token) })
                .then(prop('data'))
                .catch(error => {
                Session.emit('session-closed');
                return Promise.reject(error);
            });
        })
            .map(data => {
            return Boolean(data.uuid) && afterAuthentication(data);
        })
            .match({
            Ok: always(true),
            Aborted: destroySessionAndReturnFalse,
            Error: destroySessionAndReturnFalse
        });
    },
});
function removeTokenCookie() {
    return deleteCookie('token');
}
function readTokenFromCookie() {
    return Optional
        .fromNullable(getCookie('token'))
        .filter(Boolean);
}
function storeUser(value) {
    storeByKey('loggedUser', value);
}
function removeUser() {
    localStorage.removeItem('loggedUser');
}
function readTokenFromStoredUser() {
    return readStoredUser()
        .property('token');
}
function readStoredUser() {
    return Result
        .expect(localStorage.getItem('loggedUser'))
        .map(JSON.parse)
        .toOptional();
}
function storeByKey(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}
function makeAuthorizationHeaders(token) {
    return { authorization: `Bearer ${token}` };
}
function afterAuthentication(user, setHeader = true) {
    loggedUser = Optional.Some(user);
    storeUser(user);
    if (setHeader) {
        configureTokenHeader(loggedUser);
    }
}
function configureTokenHeader(optionalUser) {
    const commonHeaders = backend.defaults.headers.common;
    optionalUser.match({
        Some: user => { Object.assign(commonHeaders, makeAuthorizationHeaders(user.token)); },
        None: () => { delete commonHeaders.authorization; }
    });
}
export default Session;
