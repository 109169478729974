import { createSchedule } from 'shared/schedule';
import { Mutations, QueryMutations } from '@/store/Mutations';
import { Actions, parachute } from '@/store/Actions';
import axios from '@/backend';
export const mutations = {
    create(state) {
        state.editing = createSchedule();
    },
    refresh: Mutations.refresh,
    updateOne: Mutations.updateOne,
    cancelEdit: Mutations.cancelEdit,
    toEdit: Mutations.toEdit,
    toDelete: Mutations.toDelete,
    deleted: Mutations.deleted,
    cancelDelete: Mutations.cancelDelete,
    extend: Mutations.extend,
    refreshHistory(state, items) {
        state.history = items;
        console.log(`refresh history ${state.name}`);
    },
    setActiveQuery: QueryMutations.setActiveQuery,
    conserveActiveQuery: QueryMutations.conserveActiveQuery,
};
export const actions = {
    fetchAll: Actions.fetchAll,
    fetchOne: Actions.fetchOne,
    save: Actions.save,
    delete: Actions.delete,
    fetchHistory: parachute(async ({ commit, state }, params) => {
        console.log(`${state.name}: action fetchHistory (params: ${params})`);
        let response;
        if (params) {
            response = await axios.get(`/api/${state.name}/history`, {
                params: {
                    sortBy: params.sortBy,
                    descending: params.descending,
                    search: params.search,
                    filters: JSON.stringify(params.filters),
                }
            });
        }
        else {
            response = await axios.get(`/api/${state.name}/history`);
        }
        commit('refreshHistory', response.data.data.items);
    }),
    toggleScheduleHistoryItem: parachute(async ({ commit, state }, item) => {
        console.log(`${state.name}: action toggle schedule aupairs (item: ${item.uuid})`);
        const response = await axios.put(`/api/${state.name}/history/toggle/${item.uuid}`);
    }),
};
export const getters = {
    all: (state) => {
        return state.all;
    },
    editing: (state) => {
        return state.editing;
    },
    deleting: (state) => {
        return state.deleting;
    },
    history: (state) => {
        return state.history;
    },
    activeQuery: (state) => {
        return state.activeQuery || (localStorage[state.name] ? JSON.parse(localStorage[state.name]) : undefined);
    },
};
export const state = {
    all: [],
    name: 'schedules',
    editing: undefined,
    activeQuery: undefined,
    deleting: undefined,
    history: [],
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
