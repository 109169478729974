export const defaultAupair = {
    uuid: undefined,
    id: null,
    completed: false,
    nationality: '',
    firstname: '',
    lastname: '',
    title: '',
    passport: '',
    chbxPassport: false,
    dateBirth: null,
    streetOrig: '',
    cityOrig: '',
    countryOrig: '',
    streetCurr: '',
    cityCurr: '',
    countryCurr: 'LU',
    email: '',
    languages: [],
    langCours: [],
    dateStart: null,
    dateEnd: null,
    dateEndEffective: null,
    dateRefusal: null,
    chbxApprobation: false,
    chbxMedicalCert: false,
    chbxScolarity: false,
    chbxSchoolCert: false,
    chbxHonorDeclaration: false,
    chbxWeeklyScheduleReceived: false,
    chbxRegistrationCertificate: false,
    chbxCertificateOfResidence: false,
    dateInsuranceStart: null,
    dateInsuranceEnd: null,
    matricule: '',
    phone: '',
    chbxCcssCert: false,
    notes: [],
    covenants: [],
    infoSessions: { infoSession1: [], infoSession2: [] }
};
export const defaultFamily = {
    uuid: undefined,
    id: null,
    completed: false,
    address: [{
            street: '',
            postcode: '',
            city: ''
        }],
    chbxFormSubmitted: false,
    chbxCompositionCert: false,
    dateApproval: null,
    dateWithdrawal: null,
    archived: false,
    oldFamilyId: '',
    covenants: [],
    members: [],
    children: [],
    notes: [],
    visits: []
};
export const defaultMember = {
    firstname: '',
    lastname: '',
    matricule: '',
    familyRole: '',
    occupation: '',
    phone: '',
    email: '',
    chbxCriminalRecord: false,
    casJudicNote: ''
};
export const defaultChild = {
    firstname: '',
    lastname: '',
    dateBirth: null,
    chbxScolarity: false,
    chbxDaycare: false
};
export const defaultVisit = {
    dateVisit: null,
    dateAnnounced: null,
    result: '',
    action: '',
    reason: 0
};
export const defaultInfoSession = {
    uuid: undefined,
    id: '',
    title: '',
    date: null,
    location: '',
    type: 1,
    aupairs: []
};
export const defaultTemplate = {
    uuid: undefined,
    id: '',
    name: '',
    value: '',
    module: '',
    showId: false,
    showAddress: false,
    showPlaceAndDate: false,
    place: 'Luxembourg',
    showSenderInfo: false,
    type: 'editor',
    sender: 'Dossier suivi par :\nTél. :\nE-mail :',
    dateCreated: '',
    dateModified: ''
};
export const defaultEmailTemplate = {
    uuid: undefined,
    name: '',
    subject: '',
    text: '',
    html: '',
    criteria: '',
    attachment: '',
};
