import { DateOperators, DatePeriods } from 'shared/filters';
export const ScheduleActions = {
    EMAIL: 'email',
};
export const ScheduleTargets = {
    AUPAIR: 'aupair',
};
export const SchedulePeriods = DatePeriods;
export const ScheduleOperators = DateOperators;
export function createSchedule() {
    return {
        name: '',
        active: false,
        target: ScheduleTargets.AUPAIR,
        value: {
            amount: 1,
            period: SchedulePeriods.DAYS,
            operator: ScheduleOperators.BEFORE,
            date: null,
        },
        action: ScheduleActions.EMAIL,
        emailTemplate: null,
    };
}
