import { generateCsv, generateXls } from '@/assets/generateExports';
import localDownload from '@/assets/localDownload';
import { DateTime } from 'luxon';
import axios from '@/backend';
import i18n from '@/translator';
export function parachute(λ) {
    return function withParachute(context, ...otherParameters) {
        return λ.apply(this, [context, ...otherParameters]).catch(error => {
            console.error(error);
            if (error.response.status === 400 && error.response.data.variable) {
                context.commit('snackbar/displayMessage', { message: i18n.t('errors.template', { variable: error.response.data.variable }), color: 'error', translate: false, timeout: 15000 }, { root: true });
            }
            else {
                context.commit('snackbar/displayMessage', { message: 'errors.server', color: 'error' }, { root: true });
            }
        });
    };
}
export const Actions = {
    fetchAll: parachute(async ({ commit, state }, params) => {
        let response;
        if (params) {
            response = await axios.get(`/api/${state.name}`, {
                params: {
                    basic: params.basic,
                    module: params.module,
                    sortBy: params.sortBy,
                    page: params.page,
                    rowsPerPage: params.rowsPerPage,
                    descending: params.descending,
                    search: params.search,
                    filters: JSON.stringify(params.filters),
                    showArchived: params.showArchived
                }
            });
        }
        else {
            response = await axios.get(`/api/${state.name}`);
        }
        if ((params === null || params === void 0 ? void 0 : params.page) && (params === null || params === void 0 ? void 0 : params.rowsPerPage)) {
            commit('refreshPaginated', response.data);
        }
        else {
            commit('refresh', response.data.items);
        }
    }),
    fetchAllAvailable: parachute(async ({ commit, state }) => {
        const response = await axios.get(`/api/${state.name}`, {
            params: {
                getAvailable: true
            }
        });
        commit('refreshAllAvailable', response.data);
    }),
    fetchOne: parachute(async ({ commit, state }, itemUuid) => {
        const response = await axios.get(`/api/${state.name}/${itemUuid}`);
        commit('toEdit', response.data.content);
    }),
    save: parachute(async ({ commit, state }) => {
        const itemUuid = state.editing.uuid;
        let response;
        if (state.editing.uuid === undefined) {
            response = await axios.post(`/api/${state.name}`, state.editing);
            commit('extend', response.data.data);
            commit('cancelEdit');
            return response.data.data.uuid;
        }
        response = await axios.put(`/api/${state.name}/${itemUuid}`, state.editing);
        commit('updateOne', state.editing);
        commit('cancelEdit');
    }),
    delete: parachute(async ({ commit, state }) => {
        const itemUuid = state.deleting.uuid || state.deleting;
        await axios.delete(`/api/${state.name}/${itemUuid}`);
        commit('deleted');
    })
};
export const DocumentActions = {
    uploadFile: parachute(async ({ commit, state }, formData) => {
        const response = await axios.post(`/api/${state.name}/file`, formData);
        return response.data.data;
    }),
    replaceFile: parachute(async ({ commit, state }, formData) => {
        const response = await axios.post(`/api/${state.name}/replace-file`, formData);
        return response.data.data;
    }),
    updateFile: parachute(async ({ commit, state }, file) => {
        const itemId = file.uuid;
        await axios.put(`/api/${state.name}/file/${itemId}`, file);
    }),
    deleteFile: parachute(async ({ commit, state }, file) => {
        await axios.delete(`/api/${state.name}/file/${file.uuid}`);
    }),
    downloadFile: parachute(async ({ commit, state }, file) => {
        const response = await axios.get(`/api/${state.name}/file/${file.uuid}`);
        localDownload(Buffer.from(response.data.data.data), response.data.type, response.data.filename);
    }),
    downloadFilledPDF: parachute(async ({ commit, state }, { template, payload, module }) => {
        const response = await axios.post(`/api/${state.name}/download-filled-pdf/${template.uuid}`, { payload, module });
        localDownload(Buffer.from(response.data.data.data), response.data.type, response.data.filename);
    })
};
export const NoteActions = {
    createNote: parachute(async ({ commit, state }) => {
        const defaultNote = {
            date: DateTime.local().toISODate(),
            author: 'unknown',
            label: '',
            description: '',
            refUuid: state.editing.uuid,
            refType: state.name
        };
        const response = await axios.post('/api/notes', defaultNote);
        commit('addNote', response.data.data);
    }),
    updateNote: parachute(async ({ commit, state }, note) => {
        await axios.put(`/api/notes/${note.uuid}`, note);
        commit('updateNote', note);
    }),
    removeNote: parachute(async ({ commit, state }, note) => {
        await axios.delete(`/api/notes/${note.uuid}`);
        commit('removeNote', note.uuid);
    })
};
export const ExportActions = {
    exportData: parachute(async ({ state }, { data, xls }) => {
        if (xls) {
            localDownload(generateXls(data, state.name), 'application/octet-stream', `${state.name}.xlsx`);
        }
        else {
            localDownload(generateCsv(data), 'text/csv', `${state.name}.csv`);
        }
    })
};
export const StatisticsActions = {
    fetchStatistics: parachute(async ({ commit, state }) => {
        const response = await axios.post(`/api/${state.name}/statistics`);
        commit('setStatistics', response.data.data);
    })
};
export const DashboardActions = {
    fetchDashboard: parachute(async ({ commit, state }) => {
        const response = await axios.get('/api/dashboard', {
            params: {
                general: state.settings.general,
                queries: JSON.stringify(state.settings.queries)
            }
        });
        commit('setDashboard', response.data);
    }),
    fetchQueries: parachute(async ({ commit, state }, module) => {
        const response = await axios.get('/api/queries', { params: module });
        commit('setQueries', response.data);
    }),
    saveQuery: parachute(async ({ commit, state }, query) => {
        const response = await axios.post('/api/queries', query);
        commit('extendQueries', response.data.data);
    }),
    deleteQuery: parachute(async ({ commit, state }) => {
        await axios.delete(`/api/queries/${state.deleting.uuid}`);
        commit('queryDeleted', state.deleting);
    })
};
