export default function localDownload(string, type, fileName) {
    return new Promise(resolve => {
        const blob = new Blob([string], { type });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('href', url);
        link.setAttribute('target', '_blank');
        link.setAttribute('download', fileName);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
            resolve();
        }, 100);
    });
}
