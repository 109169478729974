export default {
    en: {
        aa: 'Afar',
        ab: 'Abkhazian',
        af: 'Afrikaans',
        ak: 'Akan',
        am: 'Amharic',
        an: 'Aragonese',
        ar: 'Arabic',
        as: 'Assamese',
        av: 'Avar',
        ay: 'Aymara',
        az: 'Azerbaijani',
        ba: 'Bashkir',
        be: 'Belarusian',
        bg: 'Bulgarian',
        bh: 'Bihari',
        bi: 'Bislama',
        bm: 'Bambara',
        bn: 'Bengali',
        bo: 'Tibetan',
        br: 'Breton',
        bs: 'Bosnian',
        ca: 'Catalan',
        ce: 'Chechen',
        ch: 'Chamorro',
        co: 'Corsican',
        cr: 'Cree',
        cs: 'Czech',
        cu: 'Old Church Slavonic',
        cv: 'Chuvash',
        cy: 'Welsh',
        da: 'Danish',
        de: 'German',
        dv: 'Divehi',
        dz: 'Dzongkha',
        ee: 'Ewe',
        el: 'Greek',
        en: 'English',
        eo: 'Esperanto',
        es: 'Spanish',
        et: 'Estonian',
        eu: 'Basque',
        fa: 'Persian',
        ff: 'Peul',
        fi: 'Finnish',
        fj: 'Fijian',
        fo: 'Faroese',
        fr: 'French',
        fy: 'West Frisian',
        ga: 'Irish',
        gd: 'Scottish Gaelic',
        gl: 'Galician',
        gn: 'Guarani',
        gu: 'Gujarati',
        gv: 'Manx',
        ha: 'Hausa',
        he: 'Hebrew',
        hi: 'Hindi',
        ho: 'Hiri Motu',
        hr: 'Croatian',
        ht: 'Haitian',
        hu: 'Hungarian',
        hy: 'Armenian',
        hz: 'Herero',
        ia: 'Interlingua',
        id: 'Indonesian',
        ie: 'Interlingue',
        ig: 'Igbo',
        ii: 'Sichuan Yi',
        ik: 'Inupiak',
        io: 'Ido',
        is: 'Icelandic',
        it: 'Italian',
        iu: 'Inuktitut',
        ja: 'Japanese',
        jv: 'Javanese',
        ka: 'Georgian',
        kg: 'Kongo',
        ki: 'Kikuyu',
        kj: 'Kuanyama',
        kk: 'Kazakh',
        kl: 'Greenlandic',
        km: 'Cambodian',
        kn: 'Kannada',
        ko: 'Korean',
        kr: 'Kanuri',
        ks: 'Kashmiri',
        ku: 'Kurdish',
        kv: 'Komi',
        kw: 'Cornish',
        ky: 'Kirghiz',
        la: 'Latin',
        lb: 'Luxembourgish',
        lg: 'Ganda',
        li: 'Limburgian',
        ln: 'Lingala',
        lo: 'Laotian',
        lt: 'Lithuanian',
        lu: 'Luba-Katanga',
        lv: 'Latvian',
        mg: 'Malagasy',
        mh: 'Marshallese',
        mi: 'Maori',
        mk: 'Macedonian',
        ml: 'Malayalam',
        mn: 'Mongolian',
        mo: 'Moldovan',
        mr: 'Marathi',
        ms: 'Malay',
        mt: 'Maltese',
        my: 'Burmese',
        na: 'Nauruan',
        nb: 'Norwegian Bokmål',
        nd: 'North Ndebele',
        ne: 'Nepali',
        ng: 'Ndonga',
        nl: 'Dutch',
        nn: 'Norwegian Nynorsk',
        no: 'Norwegian',
        nr: 'South Ndebele',
        nv: 'Navajo',
        ny: 'Chichewa',
        oc: 'Occitan',
        oj: 'Ojibwa',
        om: 'Oromo',
        or: 'Oriya',
        os: 'Ossetian / Ossetic',
        pa: 'Panjabi / Punjabi',
        pi: 'Pali',
        pl: 'Polish',
        ps: 'Pashto',
        pt: 'Portuguese',
        qu: 'Quechua',
        rm: 'Raeto Romance',
        rn: 'Kirundi',
        ro: 'Romanian',
        ru: 'Russian',
        rw: 'Rwandi',
        sa: 'Sanskrit',
        sc: 'Sardinian',
        sd: 'Sindhi',
        se: 'Northern Sami',
        sg: 'Sango',
        sh: 'Serbo-Croatian',
        si: 'Sinhalese',
        sk: 'Slovak',
        sl: 'Slovenian',
        sm: 'Samoan',
        sn: 'Shona',
        so: 'Somalia',
        sq: 'Albanian',
        sr: 'Serbian',
        ss: 'Swati',
        st: 'Southern Sotho',
        su: 'Sundanese',
        sv: 'Swedish',
        sw: 'Swahili',
        ta: 'Tamil',
        te: 'Telugu',
        tg: 'Tajik',
        th: 'Thai',
        ti: 'Tigrinya',
        tk: 'Turkmen',
        tl: 'Tagalog / Filipino',
        tn: 'Tswana',
        to: 'Tonga',
        tr: 'Turkish',
        ts: 'Tsonga',
        tt: 'Tatar',
        tw: 'Twi',
        ty: 'Tahitian',
        ug: 'Uyghur',
        uk: 'Ukrainian',
        ur: 'Urdu',
        uz: 'Uzbek',
        ve: 'Venda',
        vi: 'Vietnamese',
        vo: 'Volapük',
        wa: 'Walloon',
        wo: 'Wolof',
        xh: 'Xhosa',
        yi: 'Yiddish',
        yo: 'Yoruba',
        za: 'Zhuang',
        zh: 'Chinese',
        zu: 'Zulu'
    },
    fr: {
        aa: 'afar',
        ab: 'abkhaze',
        af: 'afrikaans',
        ak: 'akan',
        am: 'amharique',
        an: 'aragonais',
        ar: 'arabe',
        as: 'assamais',
        av: 'avar',
        ay: 'aymara',
        az: 'azéri',
        ba: 'bachkir',
        be: 'biélorusse',
        bg: 'bulgare',
        bh: 'langues biharies',
        bi: 'bichlamar',
        bm: 'bambara',
        bn: 'bengali',
        bo: 'tibétain',
        br: 'breton',
        bs: 'bosniaque',
        ca: 'catalan',
        ce: 'tchétchène',
        ch: 'chamorro',
        co: 'corse',
        cr: 'cri',
        cs: 'tchèque',
        cu: 'vieux slave',
        cv: 'tchouvache',
        cy: 'gallois',
        da: 'danois',
        de: 'allemand',
        dv: 'divehi',
        dz: 'dzongkha',
        ee: 'éwé',
        el: 'grec',
        en: 'anglais',
        eo: 'espéranto',
        es: 'espagnol',
        et: 'estonien',
        eu: 'basque',
        fa: 'persan',
        ff: 'peul',
        fi: 'finnois',
        fj: 'fidjien',
        fo: 'féroïen',
        fr: 'français',
        fy: 'frison',
        ga: 'gaélique irlandais',
        gd: 'gaélique écossais',
        gl: 'galicien',
        gn: 'guarani',
        gu: 'gujarati',
        gv: 'mannois',
        ha: 'haoussa',
        he: 'hébreu',
        hi: 'hindi',
        ho: 'hiri motou',
        hr: 'croate',
        ht: 'haïtien',
        hu: 'hongrois',
        hy: 'arménien',
        hz: 'héréro',
        ia: 'interlingua',
        id: 'indonésien',
        ie: 'interlingue',
        ig: 'igbo',
        ii: 'yi',
        ik: 'inupiaq',
        io: 'ido',
        is: 'islandais',
        it: 'italien',
        iu: 'inuktitut',
        ja: 'japonais',
        jv: 'javanais',
        ka: 'géorgien',
        kg: 'kikongo',
        ki: 'kikuyu',
        kj: 'kuanyama',
        kk: 'kazakh',
        kl: 'kalaallisut',
        km: 'khmer',
        kn: 'kannara',
        ko: 'coréen',
        kr: 'kanouri',
        ks: 'kashmiri',
        ku: 'kurde',
        kv: 'komi',
        kw: 'cornique',
        ky: 'kirghiz',
        la: 'latin',
        lb: 'luxembourgeois',
        lg: 'ganda',
        li: 'limbourgeois',
        ln: 'lingala',
        lo: 'laotien',
        lt: 'lituanien',
        lu: 'louba-katanga',
        lv: 'letton',
        mg: 'malgache',
        mh: 'marshallais',
        mi: 'maori',
        mk: 'macédonien',
        ml: 'malayalam',
        mn: 'mongol',
        mo: 'moldave',
        mr: 'marathe',
        ms: 'malais',
        mt: 'maltais',
        my: 'birman',
        na: 'nauruan',
        nb: 'norvégien (bokmål)',
        nd: 'ndébélé du Nord',
        ne: 'népalais',
        ng: 'ndonga',
        nl: 'néerlandais',
        nn: 'norvégien (nynorsk)',
        no: 'norvégien',
        nr: 'ndébélé du Sud',
        nv: 'navajo',
        ny: 'nyanja',
        oc: 'occitan',
        oj: 'ojibwa',
        om: 'oromo',
        or: 'oriya',
        os: 'ossète',
        pa: 'pendjabi',
        pi: 'pali',
        pl: 'polonais',
        ps: 'pachto',
        pt: 'portugais',
        qu: 'quechua',
        rm: 'romanche',
        rn: 'kirundi',
        ro: 'roumain',
        ru: 'russe',
        rw: 'kinyarwanda',
        sa: 'sanskrit',
        sc: 'sarde',
        sd: 'sindhi',
        se: 'sami du Nord',
        sg: 'sango',
        sh: 'serbo-croate',
        si: 'cingalais',
        sk: 'slovaque',
        sl: 'slovène',
        sm: 'samoan',
        sn: 'shona',
        so: 'somali',
        sq: 'albanais',
        sr: 'serbe',
        ss: 'swazi',
        st: 'sotho du Sud',
        su: 'soundanais',
        sv: 'suédois',
        sw: 'swahili',
        ta: 'tamoul',
        te: 'télougou',
        tg: 'tadjik',
        th: 'thaï',
        ti: 'tigrigna',
        tk: 'turkmène',
        tl: 'tagalog / filipino',
        tn: 'tswana',
        to: 'tongien',
        tr: 'turc',
        ts: 'tsonga',
        tt: 'tatare',
        tw: 'twi',
        ty: 'tahitien',
        ug: 'ouïghour',
        uk: 'ukrainien',
        ur: 'ourdou',
        uz: 'ouzbek',
        ve: 'venda',
        vi: 'vietnamien',
        vo: 'volapük',
        wa: 'wallon',
        wo: 'wolof',
        xh: 'xhosa',
        yi: 'yiddish',
        yo: 'yoruba',
        za: 'zhuang',
        zh: 'chinois',
        zu: 'zoulou '
    }
};
