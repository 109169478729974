import { baseURL } from '../backend';
import { Optional } from 'lonad';
import Session from './session';
import Axios from 'axios';
import configuration from '@/client-configuration';
import { prop, pipe } from 'ramda';
export function initialState() {
    return {
        user: Session.getUser(),
    };
}
const axios = Axios.create({
    baseURL,
});
export default {
    namespaced: true,
    state: initialState(),
    actions: {
        logout: async function logout({ commit, dispatch }) {
            const logoutPageUrl = await dispatch('logoutPageUrl');
            console.log(`%c[authorization-store:logout] %credirecting to logout URL: ${logoutPageUrl}`, 'color: turquoise;', 'color: gray;');
            await Session.logout(logoutPageUrl);
            commit('setUser', Optional.None());
        },
        refresh: async function refresh({ commit }) {
            await Session.refresh();
            commit('setUser', Session.getUser());
        },
        logoutPageUrl: async function logoutPageUrl({ getters }) {
            return (await axios.get(`${configuration.server.samlPrefix || ''}/saml/logoutUrl/${getters.nameId}`)).data;
        },
        loginPageUrl: async function loginPageUrl({ state }) {
            return (await axios.get(`${configuration.server.samlPrefix || ''}/saml/loginUrl`)).data;
        },
    },
    getters: {
        user: prop('user'),
        nameId: pipe(prop('user'), Optional.property('nameId'), Optional.getOrElse('')),
        authenticated: state => prop('user', state).valuePresent,
    },
    mutations: {
        setUser: (state, user) => {
            if (!user.isOptionalInstance) {
                console.error('AUTHORIZATION STORE: cannot set non-optional user!');
                return;
            }
            state.user = user;
        },
    }
};
