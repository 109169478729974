import Vue from 'vue';
import Session from './authorization/session';
import axios from '@/backend';
import store from './Store';
import VueRouter from 'vue-router';
import { identity } from 'ramda';
Vue.use(VueRouter);
export const routes = [
    {
        path: '/',
        name: 'Dashboard',
        components: {
            default: () => import('./components/Dashboard.vue'),
            sidebar: () => import('./components/sidebars/DashboardSidebar.vue'),
        }
    },
    {
        path: '/search',
        redirect: '/search/aupairs'
    },
    {
        path: '/statistics',
        redirect: '/statistics/aupairs'
    },
    {
        path: '/settings',
        redirect: '/settings/templates'
    },
    {
        path: '/settings/templates',
        redirect: '/settings/templates/aupairs'
    },
    {
        path: '/search/covenants',
        name: 'SearchCovenant',
        components: {
            default: () => import('./components/lists/CovenantList.vue'),
            sidebar: () => import('./components/sidebars/SearchSidebar.vue'),
        },
        props: {
            default: false,
            sidebar: { module: 'covenants' }
        }
    },
    {
        path: '/search/visits',
        name: 'SearchVisits',
        components: {
            default: () => import('./components/lists/VisitList.vue'),
            sidebar: () => import('./components/sidebars/SearchSidebar.vue'),
        },
        props: {
            default: false,
            sidebar: { module: 'visits' }
        }
    },
    {
        path: '/infoSessions',
        redirect: '/search/infoSessions'
    },
    {
        path: '/search/infoSessions',
        name: 'SearchInfoSessions',
        components: {
            default: () => import('./components/lists/InfoSessionList.vue'),
            sidebar: () => import('./components/sidebars/SearchSidebar.vue'),
        },
        props: {
            default: false,
            sidebar: { module: 'infoSessions' }
        }
    },
    {
        path: '/infoSessions/form',
        name: 'InfoSessionForm',
        component: () => import('./components/forms/InfoSessionForm.vue'),
    },
    {
        path: '/infoSessions/form/:uuid',
        name: 'InfoSessionFormEdit',
        component: () => import('./components/forms/InfoSessionForm.vue'),
        props: true
    },
    {
        path: '/infoSessions/:uuid',
        name: 'InfoSessionView',
        components: {
            default: () => import('./components/views/InfoSessionView.vue'),
            sidebar: () => import('./components/sidebars/ViewSidebar.vue'),
        },
        props: {
            default: true,
            sidebar: { module: 'infoSessions' }
        }
    },
    {
        path: '/aupairs',
        redirect: '/search/aupairs'
    },
    {
        path: '/search/aupairs',
        name: 'SearchAupair',
        components: {
            default: () => import('./components/lists/AupairList.vue'),
            sidebar: () => import('./components/sidebars/SearchSidebar.vue'),
        },
        props: {
            default: false,
            sidebar: { module: 'aupairs' }
        }
    },
    {
        path: '/aupairs/form',
        name: 'AupairForm',
        component: () => import('./components/forms/AupairForm.vue'),
    },
    {
        path: '/aupairs/form/:uuid',
        name: 'AupairFormEdit',
        component: () => import('./components/forms/AupairForm.vue'),
        props: true
    },
    {
        path: '/aupairs/:uuid',
        name: 'AupairView',
        components: {
            default: () => import('./components/views/AupairView.vue'),
            sidebar: () => import('./components/sidebars/ViewSidebar.vue'),
        },
        props: {
            default: true,
            sidebar: { module: 'aupairs' }
        }
    },
    {
        path: '/families',
        redirect: '/search/families'
    },
    {
        path: '/search/families',
        name: 'SearchFamily',
        components: {
            default: () => import('./components/lists/FamilyList.vue'),
            sidebar: () => import('./components/sidebars/SearchSidebar.vue'),
        },
        props: {
            default: false,
            sidebar: { module: 'families' }
        }
    },
    {
        path: '/families/form',
        name: 'FamilyForm',
        component: () => import('./components/forms/FamilyForm.vue'),
    },
    {
        path: '/families/form/:uuid',
        name: 'FamilyFormEdit',
        component: () => import('./components/forms/FamilyForm.vue'),
        props: true
    },
    {
        path: '/families/:uuid',
        name: 'FamilyView',
        components: {
            default: () => import('./components/views/FamilyView.vue'),
            sidebar: () => import('./components/sidebars/ViewSidebar.vue'),
        },
        props: {
            default: true,
            sidebar: { module: 'families' }
        }
    },
    {
        path: '/statistics/aupairs',
        name: 'StatisticsAupair',
        components: {
            default: () => import('./components/statistics/AupairStatistics.vue'),
            sidebar: () => import('./components/sidebars/StatisticsSidebar.vue'),
        },
        props: {
            default: false,
            sidebar: { name: 'aupairs' }
        }
    },
    {
        path: '/statistics/families',
        name: 'StatisticsFamily',
        components: {
            default: () => import('./components/statistics/FamilyStatistics.vue'),
            sidebar: () => import('./components/sidebars/StatisticsSidebar.vue'),
        },
        props: {
            default: false,
            sidebar: { name: 'families' }
        }
    },
    {
        path: '/settings/templates/:module',
        name: 'SettingsTemplates',
        components: {
            default: () => import('./components/settings/TemplateSettingsList.vue'),
            sidebar: () => import('./components/sidebars/SettingsSidebar.vue'),
        },
        props: {
            default: true,
        }
    },
    {
        path: '/settings/templates/:module/form',
        name: 'TemplateSettingsForm',
        components: {
            default: () => import('./components/settings/TemplateSettingsForm.vue'),
            sidebar: () => import('./components/sidebars/SettingsSidebar.vue'),
        },
        props: {
            default: true,
        }
    },
    {
        path: '/settings/templates/:module/form/:uuid',
        name: 'TemplateSettingsFormEdit',
        components: {
            default: () => import('./components/settings/TemplateSettingsForm.vue'),
            sidebar: () => import('./components/sidebars/SettingsSidebar.vue'),
        },
        props: {
            default: true,
        }
    },
    {
        path: '/settings/email-templates',
        name: 'EmailTemplatesList',
        components: {
            default: () => import('./components/settings/EmailTemplatesList.vue'),
            sidebar: () => import('./components/sidebars/SettingsSidebar.vue'),
        },
        props: {
            default: true,
        }
    },
    {
        path: '/settings/email-templates/form/',
        name: 'EmailTemplatesForm',
        components: {
            default: () => import('./components/settings/EmailTemplatesForm.vue'),
            sidebar: () => import('./components/sidebars/SettingsSidebar.vue'),
        },
        props: {
            default: true,
        }
    },
    {
        path: '/settings/email-templates/form/:uuid',
        name: 'EmailTemplatesFormEdit',
        components: {
            default: () => import('./components/settings/EmailTemplatesForm.vue'),
            sidebar: () => import('./components/sidebars/SettingsSidebar.vue'),
        },
        props: {
            default: true,
        }
    },
    {
        path: '/settings/schedules',
        name: 'SchedulesList',
        components: {
            default: () => import('./components/settings/SchedulesList.vue'),
            sidebar: () => import('./components/sidebars/SettingsSidebar.vue'),
        },
        props: {
            default: true,
        }
    },
    {
        path: '/settings/schedules/form/',
        name: 'SchedulesForm',
        components: {
            default: () => import('./components/settings/SchedulesForm.vue'),
            sidebar: () => import('./components/sidebars/SettingsSidebar.vue'),
        },
        props: {
            default: true,
        }
    },
    {
        path: '/settings/schedules/form/:uuid',
        name: 'SchedulesFormEdit',
        components: {
            default: () => import('./components/settings/SchedulesForm.vue'),
            sidebar: () => import('./components/sidebars/SettingsSidebar.vue'),
        },
        props: {
            default: true,
        }
    },
];
const router = new VueRouter({
    routes,
});
async function redirectToLoginPage() {
    const loginPageUrl = await store.dispatch('authorization/loginPageUrl');
    if (loginPageUrl) {
        window.location.href = loginPageUrl;
    }
    else {
    }
}
axios.interceptors.response.use(identity, error => {
    var _a;
    if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401 && Session.authenticated()) {
        Session.logout();
    }
    else {
        throw error;
    }
});
Session.on('session-closed', async () => {
    return redirectToLoginPage();
});
router.beforeEach(async function beforeEachRoute(to, from, next) {
    console.log(`%c[router:before] %ctrying to navigate to ${to.path}`, 'color: turquoise;', 'color: gray;');
    try {
        await store.dispatch('authorization/refresh');
    }
    catch (_) {
    }
    if (Session.authenticated()) {
        return next();
    }
    return redirectToLoginPage();
});
export default router;
