import { DashboardActions } from '@/store/Actions';
import { Mutations } from '@/store/Mutations';
import clonedeep from 'lodash.clonedeep';
export const mutations = {
    setDashboard(state, dashboard) {
        state.dashboard = dashboard;
    },
    setQueries(state, queries) {
        state.queriesAvailable = queries;
    },
    extendQueries(state, query) {
        const module = query.module;
        state.queriesAvailable[module].push(query);
    },
    queryDeleted(state) {
        const module = state.deleting.module;
        state.queriesAvailable[module] = state.queriesAvailable[module].filter((value) => {
            return value.uuid !== state.deleting.uuid;
        });
        state.deleting = undefined;
    },
    loadSettings(state) {
        if (localStorage.settings) {
            state.settings = JSON.parse(localStorage.settings);
        }
        else {
            state.settings = clonedeep(defaultSettings);
            localStorage.settings = JSON.stringify(defaultSettings);
        }
    },
    saveSettings(state) {
        localStorage.settings = JSON.stringify(state.settings);
    },
    addQuery(state, query) {
        if (query && !state.settings.queries.includes(query)) {
            state.settings.queries.push(query);
        }
    },
    removeQuery(state, query) {
        state.settings.queries = state.settings.queries.filter(q => {
            return q !== query;
        });
        state.dashboard.queries = state.dashboard.queries.filter(q => {
            return q.uuid !== query;
        });
    },
    ...Mutations
};
export const actions = {
    ...DashboardActions
};
export const getters = {
    dashboard: (state) => {
        return state.dashboard;
    },
    queries: (state) => {
        return state.queriesAvailable;
    },
    general: (state) => {
        return state.dashboard.general || {};
    },
    permanentQueries: (state) => {
        return state.dashboard.permanentQueries || {};
    },
    queriesNotSelected: (state) => {
        return state.queriesAvailable.aupairs
            .concat(state.queriesAvailable.families)
            .concat(state.queriesAvailable.covenants)
            .concat(state.queriesAvailable.visits)
            .concat(state.queriesAvailable.infoSessions)
            .concat(state.queriesAvailable.schedulesAupairs)
            .filter(q => !state.settings.queries.includes(q.uuid));
    },
    settings: (state) => {
        return state.settings;
    },
    deleting: (state) => {
        return state.deleting;
    }
};
export const defaultSettings = {
    general: {
        covenantExpire: 10,
        infoSessionUp: 30
    },
    queries: []
};
export const state = {
    settings: {
        general: {},
        queries: []
    },
    dashboard: {
        aupairArrival: [],
        aupairEnd: [],
        covenantExpire: [],
        infoSessionUp: [],
        queries: []
    },
    queriesAvailable: {
        aupairs: [],
        families: [],
        covenants: [],
        visits: [],
        infoSessions: [],
        schedulesAupairs: [],
    },
    deleting: undefined,
    name: 'dashboard'
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
