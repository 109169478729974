import Vue from 'vue';
import * as Vuex from 'vuex';
import visits from './store/Visits';
import aupairs from './store/Aupairs';
import snackbar from './store/Snackbar';
import families from './store/Families';
import covenants from './store/Covenants';
import dashboard from './store/Dashboard';
import templates from './store/Templates';
import schedules from './store/Schedules';
import infoSessions from './store/InfoSessions';
import emailTemplates from './store/EmailTemplates';
import authorization from './authorization/authorization-store';
Vue.use(Vuex);
const options = {
    modules: {
        emailTemplates,
        authorization,
        infoSessions,
        schedules,
        templates,
        dashboard,
        covenants,
        families,
        snackbar,
        aupairs,
        visits
    }
};
export default new Vuex.Store(options);
