import { defaultTemplate } from '@/store/defaults';
import { Mutations } from '@/store/Mutations';
import { Actions, DocumentActions } from '@/store/Actions';
import clonedeep from 'lodash.clonedeep';
export const mutations = {
    create(state) {
        console.log('template create');
        state.editing = clonedeep(defaultTemplate);
    },
    ...Mutations
};
export const actions = {
    ...Actions,
    ...DocumentActions,
};
export const getters = {
    all: (state) => {
        return state.all;
    },
    editing: (state) => {
        return state.editing;
    },
    deleting: (state) => {
        return state.deleting;
    }
};
export const state = {
    all: [],
    name: 'templates',
    editing: undefined,
    deleting: undefined,
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
