export const mutations = {
    displayMessage(state, { message, color, translate = true, timeout = 5000 }) {
        state.translate = translate;
        state.message = message;
        state.color = color || 'error';
        state.open = true;
        state.timeout = timeout;
    },
    hide(state) {
        state.message = undefined;
        state.open = undefined;
        state.timeout = null;
    },
    setOpen(state, open) {
        if (!open) {
            mutations.hide(state);
        }
    }
};
export const getters = {
    translate: state => state.translate,
    message: state => state.message,
    isOpen: state => state.open,
    color: state => state.color,
    timeout: state => state.timeout,
};
export const state = {
    translate: false,
    message: undefined,
    color: 'error',
    open: false,
    timeout: null,
};
export default {
    mutations,
    getters,
    state,
    namespaced: true
};
