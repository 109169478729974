import { concatLanguages, getNationalityName } from 'shared/countries';
import mapValues from 'lodash.mapvalues';
import { omit } from 'ramda';
import { utils as xlsxUtils, write as xlsxWrite } from 'xlsx';
import map from 'lodash.map';
import { getActiveCovenants } from 'shared/covenants';
function prepareItems(allItems) {
    return allItems.map(item => {
        item = omit(['uuid', 'fullName', 'infoSessionMissed1', 'infoSessionMissed2'], item);
        item = mapValues(item, (value, key, object) => {
            var _a;
            if (key === 'address') {
                value = map(value, a => `${a.street}, ${a.postcode}, ${a.city}`).join(', ');
            }
            if (key === 'covenants') {
                value = (_a = getActiveCovenants(item)) === null || _a === void 0 ? void 0 : _a.emails;
            }
            if (key === 'languages') {
                value = concatLanguages(value);
            }
            if (key === 'nationality') {
                value = getNationalityName(value);
            }
            if (typeof value === 'boolean') {
                value = (value) ? 'YES' : 'NO';
            }
            return value;
        });
        item.family_emails = item.covenants;
        delete item.covenants;
        if (item.aupairs) {
            item.emails = map(item.aupairs, a => a.email).filter(Boolean).join(', ');
            item.aupairs = map(item.aupairs, a => a.name).join(', ');
        }
        return item;
    });
}
export function generateCsv(allItems) {
    const worksheet = xlsxUtils.json_to_sheet(prepareItems(allItems));
    return xlsxUtils.sheet_to_csv(worksheet);
}
;
export function generateXls(allItems, name) {
    const worksheet = xlsxUtils.json_to_sheet(prepareItems(allItems));
    const workbook = xlsxUtils.book_new();
    xlsxUtils.book_append_sheet(workbook, worksheet, name);
    return xlsxWrite(workbook, { bookType: 'xlsx', bookSST: false, type: 'buffer' });
}
;
export default { generateCsv, generateXls };
