import { getCountryName, getNationalityName } from 'shared/countries';
import { getUuid } from 'shared/get-uuid';
import Vue from 'vue';
export const Mutations = {
    refresh(state, items) {
        state.all = items;
    },
    refreshPaginated(state, results) {
        state.allPaginated = results.items;
        state.totalCount = results.totalCount;
    },
    refreshAllAvailable(state, items) {
        if (items.countryOrig && items.countryOrig.length > 0) {
            items.countryOrig = items.countryOrig.map(c => ({ text: getCountryName(c), value: c }));
        }
        if (items.nationality && items.nationality.length > 0) {
            items.nationality = items.nationality.map(c => ({ text: getNationalityName(c), value: c }));
        }
        state.allAvailable = items;
    },
    extend(state, item) {
        state.all.push(item);
    },
    toEdit(state, itemToEdit) {
        state.editing = itemToEdit;
    },
    cancelEdit(state) {
        state.editing = undefined;
    },
    updateOne(state, updated) {
        const index = state.all.findIndex(item => getUuid(item) === getUuid(updated));
        if (index === -1) {
            state.all.push(updated);
        }
        else {
            Vue.set(state.all, index, updated);
        }
    },
    toDelete(state, itemToDeleteId) {
        state.deleting = itemToDeleteId;
    },
    deleted(state) {
        const index = state.all.findIndex(item => getUuid(item) === getUuid(state.deleting));
        if (index > -1) {
            Vue.delete(state.all, index);
        }
        state.deleting = undefined;
    },
    cancelDelete(state) {
        state.deleting = undefined;
    },
    setStatistics(state, statistics) {
        state.statistics = statistics;
    }
};
export const CovenantMutations = {
    addCovenant(state, { aupairName, aupairId, aupairUuid, familyName, familyId, familyUuid }) {
        state.editing.covenants.push({
            familyName,
            familyId,
            familyUuid,
            aupairName,
            aupairId,
            aupairUuid,
            dateStart: null,
            dateEnd: null,
            chbxSigned: false
        });
    },
    removeCovenant(state, { item, covenantIndex }) {
        if (covenantIndex > -1) {
            item.covenants.splice(covenantIndex, 1);
        }
    }
};
export const NoteMutations = {
    addNote(state, note) {
        state.editing.notes.push(note);
    },
    updateNote(state, updatedNote) {
        if (state.editing.notes.length === 0) {
            state.editing.notes.push(updatedNote);
        }
        else {
            state.editing.notes = state.editing.notes.map(note => {
                return getUuid(note) === getUuid(updatedNote) ? updatedNote : note;
            });
        }
    },
    removeNote(state, noteUuid) {
        state.editing.notes = state.editing.notes.filter(note => getUuid(note) !== noteUuid);
    }
};
export const QueryMutations = {
    setActiveQuery(state, query) {
        state.activeQuery = query;
        localStorage.setItem(state.name, JSON.stringify(query));
    },
    conserveActiveQuery(state, query) {
        localStorage.setItem(state.name, JSON.stringify(query));
    }
};
