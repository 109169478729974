import { defaultEmailTemplate } from '@/store/defaults';
import { Mutations } from '@/store/Mutations';
import { Actions } from '@/store/Actions';
import clonedeep from 'lodash.clonedeep';
export const mutations = {
    create(state) {
        state.editing = clonedeep(defaultEmailTemplate);
    },
    refresh: Mutations.refresh,
    cancelEdit: Mutations.cancelEdit,
    updateOne: Mutations.updateOne,
    toEdit: Mutations.toEdit,
    toDelete: Mutations.toDelete,
    deleted: Mutations.deleted,
    cancelDelete: Mutations.cancelDelete,
    extend: Mutations.extend,
};
export const actions = {
    fetchAll: Actions.fetchAll,
    fetchOne: Actions.fetchOne,
    save: Actions.save,
    delete: Actions.delete,
};
export const getters = {
    all: (state) => {
        return state.all;
    },
    editing: (state) => {
        return state.editing;
    },
    deleting: (state) => {
        return state.deleting;
    }
};
export const state = {
    all: [],
    name: 'emailTemplates',
    editing: undefined,
    deleting: undefined,
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
