import Vuetify from 'vuetify';
import router from './Router';
import store from './Store';
import i18n from '@/translator';
import Vue from 'vue';
import App from './App';
import * as VeeValidate from 'vee-validate';
import { config, dictionary, matriculeRule, dateBirthRule } from '@/assets/validator';
Vue.config.productionTip = false;
Vue.use(Vuetify);
Vue.use(VeeValidate, config);
VeeValidate.Validator.extend(matriculeRule.name, matriculeRule.rules);
VeeValidate.Validator.extend(dateBirthRule.name, dateBirthRule.rules);
VeeValidate.Validator.updateDictionary(dictionary);
VeeValidate.Validator.localize('en');
new Vue({
    router,
    store,
    i18n,
    el: '#app',
    components: { App },
    template: '<App/>'
});
