import Snackbar from '@/components/common/Snackbar';
export default {
    data() {
        return {
            langToggle: 0,
            activeTab: 0,
            version: VERSION
        };
    },
    $_veeValidate: { validator: 'new' },
    created() {
        const language = localStorage.language || this.$i18n.locale;
        this.langToggle = language;
        this.$i18n.locale = language;
        this.$validator.localize(language);
    },
    computed: {
        year() {
            const year = new Date().getFullYear();
            return (year > 2017) ? `- ${year}` : '';
        }
    },
    methods: {
        langChange(value) {
            localStorage.language = value;
            this.$i18n.locale = value;
        },
        async logout() {
            await this.$store.dispatch('authorization/logout');
        },
    },
    components: {
        Snackbar
    }
};
