export default {
    en: {
        AF: 'Afghan',
        AL: 'Albanian',
        DZ: 'Algerian',
        AS: 'American Samoan',
        AO: 'Angolan',
        AI: 'Anguillian',
        AG: 'Antiguan, Barbudan',
        AR: 'Argentinean',
        AM: 'Armenian',
        AW: 'Aruban',
        AU: 'Australian',
        AT: 'Austrian',
        AZ: 'Azerbaijani',
        BS: 'Bahamian',
        BH: 'Bahraini',
        BD: 'Bangladeshi',
        BB: 'Barbadian',
        BY: 'Belarusian',
        BE: 'Belgian',
        BZ: 'Belizean',
        BJ: 'Beninese',
        BM: 'Bermudian',
        BT: 'Bhutanese',
        BO: 'Bolivian',
        BA: 'Bosnian, Herzegovinian',
        BW: 'Motswana',
        BR: 'Brazilian',
        IO: 'Indian',
        BN: 'Bruneian',
        BG: 'Bulgarian',
        BF: 'Burkinabe',
        BI: 'Burundian',
        KH: 'Cambodian',
        CM: 'Cameroonian',
        CA: 'Canadian',
        CV: 'Cape Verdian',
        KY: 'Caymanian',
        CF: 'Central African',
        TD: 'Chadian',
        CL: 'Chilean',
        CN: 'Chinese',
        CX: 'Christmas Island',
        CC: 'Cocos Islander',
        CO: 'Colombian',
        KM: 'Comoran',
        CG: 'Congolese',
        CD: 'Congolese',
        CK: 'Cook Islander',
        CR: 'Costa Rican',
        CI: 'Ivorian',
        HR: 'Croatian',
        CU: 'Cuban',
        CY: 'Cypriot',
        CZ: 'Czech',
        DK: 'Danish',
        DJ: 'Djibouti',
        DM: 'Dominican',
        DO: 'Dominican',
        EC: 'Ecuadorean',
        EG: 'Egyptian',
        SV: 'Salvadoran',
        GQ: 'Equatorial Guinean',
        ER: 'Eritrean',
        EE: 'Estonian',
        ET: 'Ethiopian',
        FK: 'Falkland Islander',
        FO: 'Faroese',
        FJ: 'Fijian',
        FI: 'Finnish',
        FR: 'French',
        PF: 'French Polynesian',
        TF: 'French',
        GA: 'Gabonese',
        GM: 'Gambian',
        GE: 'Georgian',
        DE: 'German',
        GH: 'Ghanaian',
        GI: 'Gibraltar',
        GR: 'Greek',
        GL: 'Greenlandic',
        GD: 'Grenadian',
        GP: 'Guadeloupian',
        GU: 'Guamanian',
        GT: 'Guatemalan',
        GG: 'Channel Islander',
        GN: 'Guinean',
        GW: 'Guinea-Bissauan',
        GY: 'Guyanese',
        HT: 'Haitian',
        HM: 'Heard and McDonald Islander',
        HN: 'Honduran',
        HK: 'Chinese',
        HU: 'Hungarian',
        IS: 'Icelander',
        IN: 'Indian',
        ID: 'Indonesian',
        IR: 'Iranian',
        IQ: 'Iraqi',
        IE: 'Irish',
        IL: 'Israeli',
        IT: 'Italian',
        JM: 'Jamaican',
        JP: 'Japanese',
        JE: 'Channel Islander',
        JO: 'Jordanian',
        KZ: 'Kazakhstani',
        KE: 'Kenyan',
        KI: 'I-Kiribati',
        KP: 'North Korean',
        KR: 'South Korean',
        KW: 'Kuwaiti',
        KG: 'Kirghiz',
        LA: 'Laotian',
        LV: 'Latvian',
        LB: 'Lebanese',
        LS: 'Mosotho',
        LR: 'Liberian',
        LY: 'Libyan',
        LI: 'Liechtensteiner',
        LT: 'Lithuanian',
        LU: 'Luxembourger',
        MO: 'Chinese',
        MK: 'Macedonian',
        MG: 'Malagasy',
        MW: 'Malawian',
        MY: 'Malaysian',
        MV: 'Maldivan',
        ML: 'Malian',
        MT: 'Maltese',
        IM: 'Manx',
        MH: 'Marshallese',
        MQ: 'Martinican',
        MR: 'Mauritanian',
        MU: 'Mauritian',
        YT: 'Maorais',
        MX: 'Mexican',
        FM: 'Micronesian',
        MD: 'Moldovan',
        MC: 'Monegasque',
        MN: 'Mongolian',
        MS: 'Montserratian',
        MA: 'Moroccan',
        MZ: 'Mozambican',
        NA: 'Namibian',
        NR: 'Nauruan',
        NP: 'Nepalese',
        NL: 'Dutch',
        NC: 'New Caledonian',
        NZ: 'New Zealander',
        NI: 'Nicaraguan',
        NE: 'Nigerien',
        NG: 'Nigerian',
        NU: 'Niuean',
        NF: 'Norfolk Islander',
        MP: 'American',
        NO: 'Norwegian',
        OM: 'Omani',
        PK: 'Pakistani',
        PW: 'Palauan',
        PA: 'Panamanian',
        PG: 'Papua New Guinean',
        PY: 'Paraguayan',
        PE: 'Peruvian',
        PH: 'Filipino',
        PN: 'Pitcairn Islander',
        PL: 'Polish',
        PT: 'Portuguese',
        PR: 'Puerto Rican',
        QA: 'Qatari',
        RE: 'Réunionese',
        RO: 'Romanian',
        RU: 'Russian',
        RW: 'Rwandan',
        SH: 'Saint Helenian',
        KN: 'Kittian and Nevisian',
        LC: 'Saint Lucian',
        PM: 'French',
        VC: 'Saint Vincentian',
        WS: 'Samoan',
        SM: 'Sammarinese',
        ST: 'Sao Tomean',
        SA: 'Saudi Arabian',
        SN: 'Senegalese',
        SC: 'Seychellois',
        SL: 'Sierra Leonean',
        SG: 'Singaporean',
        SK: 'Slovak',
        SI: 'Slovene',
        SB: 'Solomon Islander',
        SO: 'Somali',
        ZA: 'South African',
        GS: 'South Georgia and the South Sandwich Islander',
        SS: 'South Sudanese',
        ES: 'Spanish',
        LK: 'Sri Lankan',
        SD: 'Sudanese',
        SR: 'Surinamer',
        SJ: 'Norwegian',
        SZ: 'Swazi',
        SE: 'Swedish',
        CH: 'Swiss',
        SY: 'Syrian',
        TW: 'Taiwanese',
        TJ: 'Tadzhik',
        TZ: 'Tanzanian',
        TH: 'Thai',
        TL: 'East Timorese',
        TG: 'Togolese',
        TK: 'Tokelauan',
        TO: 'Tongan',
        TT: 'Trinidadian',
        TN: 'Tunisian',
        TR: 'Turkish',
        TM: 'Turkmen',
        TV: 'Tuvaluan',
        UG: 'Ugandan',
        UA: 'Ukrainian',
        AE: 'Emirati',
        GB: 'British',
        US: 'American',
        UY: 'Uruguayan',
        UZ: 'Uzbekistani',
        VU: 'Ni-Vanuatu',
        VE: 'Venezuelan',
        VN: 'Vietnamese',
        WF: 'Wallis and Futuna Islander',
        EH: 'Sahrawi',
        YE: 'Yemeni',
        ZM: 'Zambian',
        ZW: 'Zimbabwean'
    },
    fr: {
        AF: 'Afghane',
        AL: 'Albanaise',
        DZ: 'Algerienne',
        AS: 'Samoan',
        AO: 'Angolaise',
        AI: 'Anguillaise',
        AG: 'Antiguaise et barbudienne',
        AR: 'Argentine',
        AM: 'Armenienne',
        AW: 'Arubaise',
        AU: 'Australienne',
        AT: 'Autrichienne',
        AZ: 'Azerbaïdjanaise',
        BS: 'Bahamienne',
        BH: 'Bahreinienne',
        BD: 'Bangladaise',
        BB: 'Barbadienne',
        BY: 'Bielorusse',
        BE: 'Belge',
        BZ: 'Belizienne',
        BJ: 'Beninoise',
        BM: 'Bermudienne',
        BT: 'Bhoutanaise',
        BO: 'Bolivienne',
        BA: 'Bosnienne',
        BW: 'Botswanaise',
        BR: 'Bresilienne',
        IO: 'Indienne',
        BN: 'Bruneienne',
        BG: 'Bulgare',
        BF: 'Burkinabe',
        BI: 'Burundaise',
        KH: 'Cambodgienne',
        CM: 'Camerounaise',
        CA: 'Canadienne',
        CV: 'Cap-verdienne',
        KY: 'Caïmanienne',
        CF: 'Centrafricaine',
        TD: 'Tchadienne',
        CL: 'Chilienne',
        CN: 'Chinoise',
        CX: 'Île Christmas',
        CC: 'Îles Cocos',
        CO: 'Colombienne',
        KM: 'Comorienne',
        CG: 'Congolaise',
        CD: 'Congolaise',
        CK: 'Îles Cook',
        CR: 'Costaricaine',
        CI: 'Ivoirienne',
        HR: 'Croate',
        CU: 'Cubaine',
        CY: 'Chypriote',
        CZ: 'Tcheque',
        DK: 'Danoise',
        DJ: 'Djiboutienne',
        DM: 'Dominiquaise',
        DO: 'Dominicaine',
        EC: 'Equatorienne',
        EG: 'Egyptienne',
        SV: 'Salvadorienne',
        GQ: 'Equato-guineenne',
        ER: 'Erythreenne',
        EE: 'Estonienne',
        ET: 'Ethiopienne',
        FK: 'Îles Malouines',
        FO: 'Féroïenne',
        FJ: 'Fidjienne',
        FI: 'Finlandaise',
        FR: 'Française',
        PF: 'Polynésienne',
        TF: 'Française',
        GA: 'Gabonaise',
        GM: 'Gambienne',
        GE: 'Georgienne',
        DE: 'Allemande',
        GH: 'Ghaneenne',
        GI: 'Gibraltarienne',
        GR: 'Hellenique',
        GL: 'Groenlandaise',
        GD: 'Grenadienne',
        GP: 'Guadeloupéenne',
        GU: 'Guamienne',
        GT: 'Guatemalteque',
        GG: 'Îles Anglo-Normandes',
        GN: 'Guineenne',
        GW: 'Bissau-Guinéenne',
        GY: 'Guyanaise',
        HT: 'Haïtienne',
        HM: 'Îles Heard-et-MacDonald',
        HN: 'Hondurienne',
        HK: 'Hongkongaise',
        HU: 'Hongroise',
        IS: 'Islandaise',
        IN: 'Indienne',
        ID: 'Indonesienne',
        IR: 'Iranienne',
        IQ: 'Irakienne',
        IE: 'Irlandaise',
        IL: 'Israélienne',
        IT: 'Italienne',
        JM: 'Jamaïcaine',
        JP: 'Japonaise',
        JE: 'Jersiaise',
        JO: 'Jordanienne',
        KZ: 'Kazakhstanaise',
        KE: 'Kenyane',
        KI: 'Kiribatienne',
        KP: 'Nord-coréenne',
        KR: 'Sud-coréenne',
        KW: 'Koweitienne',
        KG: 'Kirghize',
        LA: 'Laotienne',
        LV: 'Lettone',
        LB: 'Libanaise',
        LS: 'Lesothane',
        LR: 'Liberienne',
        LY: 'Libyenne',
        LI: 'Liechtensteinoise',
        LT: 'Lituanienne',
        LU: 'Luxembourgeoise',
        MO: 'Macanaise',
        MK: 'Macedonienne',
        MG: 'Malgache',
        MW: 'Malawienne',
        MY: 'Malaisienne',
        MV: 'Maldivienne',
        ML: 'Malienne',
        MT: 'Maltaise',
        IM: 'Mannoise',
        MH: 'Marshallaise',
        MQ: 'Martiniquaise',
        MR: 'Mauritanienne',
        MU: 'Mauricienne',
        YT: 'Mahoraise',
        MX: 'Mexicaine',
        FM: 'Micronesienne',
        MD: 'Moldave',
        MC: 'Monegasque',
        MN: 'Mongole',
        MS: 'Montserratienne',
        MA: 'Marocaine',
        MZ: 'Mozambicaine',
        NA: 'Namibienne',
        NR: 'Nauruane',
        NP: 'Nepalaise',
        NL: 'Neerlandaise',
        NC: 'Néo-Calédonienne',
        NZ: 'Neo-zelandaise',
        NI: 'Nicaraguayenne',
        NE: 'Nigerienne',
        NG: 'Nigeriane',
        NU: 'Niuéenne',
        NF: 'Norfolkaise',
        MP: 'Américaine',
        NO: 'Norvegienne',
        OM: 'Omanaise',
        PK: 'Pakistanaise',
        PW: 'Palau',
        PA: 'Panameenne',
        PG: 'Papouane-neoguineenne',
        PY: 'Paraguayenne',
        PE: 'Peruvienne',
        PH: 'Philippine',
        PN: 'Îles Pitcairn',
        PL: 'Polonaise',
        PT: 'Portugaise',
        PR: 'Portoricaine',
        QA: 'Qatarienne',
        RE: 'Réunionnaise',
        RO: 'Roumaine',
        RU: 'Russe',
        RW: 'Rwandaise',
        SH: 'Sainte-Hélène',
        KN: 'Kittitienne-et-nevicienne',
        LC: 'Saint-Lucienne',
        PM: 'Saint-Pierre-et-Miquelon',
        VC: 'Saint-Vincentaise-et-Grenadine',
        WS: 'Samoane',
        SM: 'Saint-Marinaise',
        ST: 'Santomeenne',
        SA: 'Saoudienne',
        SN: 'Senegalaise',
        SC: 'Seychelloise',
        SL: 'Sierra-leonaise',
        SG: 'Singapourienne',
        SK: 'Slovaque',
        SI: 'Slovene',
        SB: 'Salomonaise',
        SO: 'Somalienne',
        ZA: 'Sud-africaine',
        GS: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
        SS: 'Sud-Soudanaise',
        ES: 'Espagnole',
        LK: 'Sri-lankaise',
        SD: 'Soudanaise',
        SR: 'Surinamaise',
        SJ: 'Norvégienne',
        SZ: 'Swazie',
        SE: 'Suedoise',
        CH: 'Suisse',
        SY: 'Syrienne',
        TW: 'Taiwanaise',
        TJ: 'Tadjike',
        TZ: 'Tanzanienne',
        TH: 'Thaïlandaise',
        TL: 'Est-timoraise',
        TG: 'Tonguienne',
        TK: 'Tokelau',
        TO: 'Tongienne',
        TT: 'Trinidadienne',
        TN: 'Tunisienne',
        TR: 'Turque',
        TM: 'Turkmene',
        TV: 'Tuvaluane',
        UG: 'Ougandaise',
        UA: 'Ukrainienne',
        AE: 'Emirienne',
        GB: 'Britannique',
        US: 'Americaine',
        UY: 'Uruguayenne',
        UZ: 'Ouzbeke',
        VU: 'Vanuatuane',
        VE: 'Venezuelienne',
        VN: 'Vietnamienne',
        WF: 'Wallis-et-Futuna',
        EH: 'Sahraoui',
        YE: 'Yemenite',
        ZM: 'Zambienne',
        ZW: 'Zimbabweenne'
    }
};
