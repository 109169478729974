import { Mutations, QueryMutations } from '@/store/Mutations';
import { Actions, ExportActions } from '@/store/Actions';
import { defaultInfoSession } from '@/store/defaults';
import clonedeep from 'lodash.clonedeep';
import { prop } from 'ramda';
import keyBy from 'lodash.keyby';
export const mutations = {
    create(state) {
        state.editing = clonedeep(defaultInfoSession);
    },
    ...QueryMutations,
    ...Mutations
};
export const actions = {
    ...ExportActions,
    ...Actions
};
export const getters = {
    all: (state) => {
        return state.all;
    },
    activeQuery: (state) => {
        return state.activeQuery || (localStorage[state.name] ? JSON.parse(localStorage[state.name]) : undefined);
    },
    editing: (state) => {
        return state.editing;
    },
    allByUuid: (state) => {
        return keyBy(state.all, prop('uuid'));
    }
};
export const state = {
    all: [],
    allPaginated: [],
    totalCount: 0,
    activeQuery: undefined,
    name: 'infoSessions',
    editing: undefined,
    deleting: undefined,
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
