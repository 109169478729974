export function getUuid(item) {
    if (!item.uuid) {
        return undefined;
    }
    if (item.uuid) {
        return item.uuid;
    }
    return item;
}
;
