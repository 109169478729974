export const translations = {
    locale: 'fr',
    messages: {
        fr: {
            families: 'Famille | Familles',
            mrmrs: 'M./Mme.',
            aupairs: 'Au-pair | Au-pairs',
            covenant: 'Convention | Conventions',
            child: 'Enfant | Enfants',
            member: 'Membre | Membres',
            visit: 'Visite | Visites',
            infosession: 'Session d\'information | Sessions d\'information',
            note: 'Note | Notes',
            address: 'Address',
            date: 'Date',
            cancel: 'Annuler',
            confirm: 'Confirmer',
            close: 'Fermer',
            save: 'Enregistrer',
            edit: 'Modifier',
            delete: 'Supprimer',
            archive: 'Archiver',
            ':': ' :',
            noDataAvailable: 'Pas de données disponibles',
            rowsPerPage: 'Lignes par page',
            years: '{years} ans',
            toolbar: {
                dashboard: 'Tableau de bord',
                search: 'Rechercher',
                statistics: 'Statistiques',
                settings: 'Paramètres'
            },
            dashboard: {
                whatsimportant: 'Qu\'est-ce qui est important aujourd\'hui?',
                withactivecovenant: 'Au-pair avec une convention active | Au-pairs avec une convention active',
                familyactive: 'Famille avec un agrément actif | Familles avec un agrément actif',
                covenant: 'convention arrive à son terme | conventions arrivent à leur terme',
                aupairarriv: 'nouvel au-pair arrive ce mois-ci | nouveaux au-pairs arrivent ce mois-ci',
                aupairlastmonth: 'aupair devait arriver le mois précédent | aupairs devaient arriver le mois précédent',
                infosession: 'session d\'information est prévue | sessions d\'information sont prévues',
                days: 'aujourd\'hui | aujourd\'hui ou demain | dans les {count} prochains jours',
                deletequery: 'Supprimer la recherche',
                deletequerysure: 'Êtes-vous sûr de vouloir supprimer la recherche',
                savedqueries: 'Recherches sauvegardées',
                noqueries: 'Aucune recherche disponible',
                aupairs: 'S\'applique aux au-pairs',
                families: 'S\'applique aux familles',
                covenants: 'S\'applique aux conventions',
                visits: 'S\'applique aux visites',
                infoSessions: 'S\'applique aux sessions d\'information',
                seeall: 'Voir tous',
                edit: 'Modifier le tableau de bord',
                save: 'Quitter le mode d\'édition',
                remove: 'Enlever cette recherche du tableau de bord',
                addquery: 'Ajouter une recherche',
                select: 'Sélectionnez la recherche',
                notfirst: 'Au-pairs qui n\'ont pas participé à la première session d\'information',
                notsecond: 'Au-pairs qui n\'ont pas participé à la deuxième session d\'information',
                aupairsWithUncheckedBoxes: "Au-pairs qui n'ont pas soumis un document requis",
                aupairsWithSentEmail: 'Au-pairs qui ont reçu une lettre/e-mail de rappel',
                aupairsFulfilling42DaysRule: "Au-pairs qui n'ont pas soumis les documents requis après 42 jours",
                dismiss: 'Envoyé',
                dismissTitle: 'Lettre de rappel de 42 jours',
                dismissText: 'Cliquez sur « Confirmer » pour marquer cette lettre comme envoyée.',
                sentDate: 'Envoyé le',
                schedulesAupairs: "S'applique aux courriels envoyés aux au-pairs",
            },
            view: {
                age: 'Âge',
                doc: {
                    documents: 'Documents',
                    drop: 'Veuillez déposer le fichier ici ou',
                    click: 'cliquez ici pour sélectionner un fichier',
                    edit: 'Modifier l\'intitulé du document',
                    set: 'Définir l\'intitulé du document',
                    deletedoc: 'Supprimer le document',
                    deletedocsure: 'Êtes-vous sûr de vouloir supprimer le document',
                    filename: 'Nom de fichier',
                    category: 'Categorie',
                    label: 'Note',
                    typetext: 'Tapez le texte ici…',
                    nodocs: 'Aucun document…',
                    download: 'Cliquez ici pour télécharger le fichier'
                },
                note: {
                    author: 'Auteur',
                    label: 'Intitulé',
                    description: 'Description',
                    addnote: 'Ajouter une note'
                },
                missingdate: 'Date manquante',
                arrival: 'Arrivée',
                infosession: '1<sup>re</sup> session d\'information | 2<sup>me</sup> session d\'information',
                departure: 'Départ',
                letter: 'Lettre',
                selecttemplate: 'Sélectionnez le modèle',
                generateletter: 'Générer une lettre',
                firstinfosession: 'Première session d\'information',
                secondinfosession: 'Deuxième session d\'information',
                invitation: {
                    1: '1<sup>re</sup> invitation',
                    2: '2<sup>e</sup> invitation',
                    3: '3<sup>e</sup> invitation'
                }
            },
            form: {
                editaupair: 'Modifier l\'au-pair',
                newaupair: 'Créer un nouvel au-pair',
                basic: 'Informations de base',
                id: 'ID',
                nationality: 'Nationalité',
                birth: 'Date de naissance',
                age: 'Âge',
                lastname: 'Nom',
                firstname: 'Prénom',
                email: 'E-mail',
                pass: 'Numéro de passeport',
                passcopy: 'Copie du passeport reçue',
                stay: 'Informations sur le séjour',
                beginstay: 'Début du séjour',
                endstay: 'Fin prévue du séjour',
                endeff: 'Fin effective du séjour',
                noendeff: 'Pas de fin effective du séjour',
                refused: 'Date de refus',
                stayfor: 'Durée du séjour',
                days: 'jour | jours',
                approb: 'Demande d\'approbation reçue',
                medcert: 'Certificat médical vérifié',
                scholar: 'Conditions de scolarité',
                schoolcert: 'Certificat de scolarité',
                insurance: 'Assurance & Sécurité sociale',
                insurstart: 'Affiliation entrée',
                insurend: 'Affiliation sortie',
                matricule: 'Matricule',
                language: 'Langue',
                languages: 'Langues',
                langspoken: 'Langues parlées',
                langcours: 'Cours de langue',
                addresscurr: 'Adresse locale',
                street: 'Rue',
                city: 'Ville',
                country: 'Pays',
                addressorig: 'Adresse à l\'étranger',
                addcovenant: 'Ajouter une convention',
                scholarity: 'Remplit les conditions de scolarité',
                daycare: 'Preuve d\'accueil de jour fournie',
                startdate: 'Date d\'arrivée',
                enddate: 'Date de départ',
                signed: 'Signée',
                notsigned: 'Pas signé',
                editfamily: 'Modifier la famille',
                newfamily: 'Créer une nouvelle famille',
                name: 'Nom',
                postcode: 'Code postal',
                addaddress: 'Ajouter une ligne d\'adresse',
                agreement: 'Agrément',
                numagreem: 'Numéro d\'agrément',
                approval: 'Agrément émis le',
                withdrawal: 'Agrément revoqué le',
                submitted: 'Formulaire de demande d\'agrément reçu',
                composition: 'Certificat de composition de menage (< 3 mois) reçu',
                addmember: 'Ajouter un membre',
                addchild: 'Ajouter un enfant',
                addvisit: 'Ajouter une visite',
                editinfosession: 'Modifier la session d\'information',
                newinfosession: 'Créer une session d\'information',
                title: 'Titre',
                location: 'Endroit',
                participants: 'Participants',
                participated: 'Participé',
                notparticipated: 'Pas participé',
                confirmed: 'Présence confirmée',
                notconfirmed: 'Pas confirmé',
                motive: 'Motif',
                familyrole: 'Rôle dans la famille',
                phone: 'Numéro de téléphone',
                occupation: 'Occupation',
                crimrecord: 'Entrée dans le casier judiciaire',
                noterecord: 'Note casier judiciaire',
                datevisit: 'Date de la visite',
                dateannounced: 'Date d\'annonce',
                announced: 'Annoncé',
                reason: 'Raison',
                result: 'Résultat',
                action: 'Action',
                from: 'De',
                until: 'Jusqu\'à',
                yes: 'Oui',
                no: 'Non',
                nocovenants: 'Aucune convention disponible',
                noaupairs: 'Aucun au-pair n\'a participé',
                deleteaupairs: 'Supprimer l\'au-pair',
                deletefamilies: 'Supprimer la famille',
                deleteinfoSessions: 'Supprimer la session d\'information',
                deletesureaupairs: 'Êtes-vous sûr de vouloir supprimer l\'au-pair',
                deletesurefamilies: 'Êtes-vous sûr de vouloir supprimer la famille',
                deletesureinfoSessions: 'Êtes-vous sûr de vouloir supprimer la session d\'information',
                archivefamily: 'Archiver la famille',
                createnewfamily: 'Créer une nouvelle famille',
                previousfamily: 'Famille précédente',
                movecovenants: 'Déplacer les conventions actives vers la nouvelle famille',
                copychildren: 'Copier les enfants :',
                copymembers: 'Copier les membres :',
                familyisarchived: 'La famille est archivée',
                aboutarchive: 'Vous êtes sur le point d\'archiver la famille <strong>{family}</strong>. Les familles archivées ne sont pas visibles dans la liste des familles.',
                noresults: 'Aucun résultat',
                completed: 'Vérifié(e) comme complet',
                notcompleted: 'Pas vérifié(e)',
                mr: 'Monsieur',
                mrs: 'Madame',
                overlapping: 'Les dates des conventions ne doivent pas se chevaucher',
                infosessiontype: "Type de session d'information",
                ccsscert: 'Certificat CCSS d\'affiliation soumis',
                sendinvitationmail: 'Envoyer mail ',
                unknown: 'Information indisponible',
                insertplaceholder: 'Insérer un texte de substitution…',
                insertsymbol: 'Insérer un symbole spécial…',
                checkbox: 'Case à cocher (cochée)',
                checkboxUnchecked: 'Case à cocher (non cochée)',
                aupairname: "Nom de l'au-pair",
                aupairid: "Identifiant de l'au-pair",
                aupairtitle: "Titre de l'au-pair",
                familyname: 'Nom de la famille',
                familyid: 'Identifiant de la famille',
                honordeclaration: "Déclaration sur l'honneur concernant le lien familial",
                weeklyschedule: 'Planning hebdomadaire reçu',
                registrationcertificate: 'Déclaration de la commune',
                certificateofresidence: 'Certificat de résidence – du domicile actuel',
                checklist: 'Checklist'
            },
            list: {
                addaupair: 'Ajouter un au-pair',
                addfamily: 'Ajouter une famille',
                addinfosession: 'Ajouter une session d\'information',
                listaupairs: 'Liste des au-pairs',
                listcovenants: 'Liste des conventions',
                listfamilies: 'Liste des familles',
                listvisits: 'Liste des visites',
                listinfosessions: 'Liste des sessions d\'information',
                search: 'Rechercher',
                noinfosession: 'N\'a pas participé à la première session d\'information | N\'a pas participé à la deuxième session d\'information',
                nosocsec: 'Numéro de sécurité sociale non renseigné',
                from: 'De',
                to: 'à',
                of: 'de',
                all: 'Tout',
                numcovenants: 'Nombre de conventions',
                numchildren: 'Nombre de enfants',
                nummembers: 'Nombre de membres',
                arrivalmonth: 'Mois d\'arrivée',
                stayfor: 'Durée du séjour (jours)',
                addtemplate: 'Ajouter un nouveau modèle',
                toggleselection: 'Activer la sélection',
                showarchived: 'Afficher archivé',
                labels: 'étiquettes',
                type: 'Type',
                1: 'Première',
                2: 'Deuxième',
                invitation: 'Invitation',
                presence: 'Présence',
                addEmailTemplate: 'Ajouter un modèle de courriel',
            },
            filter: {
                add: 'Ajouter un filtre',
                editfilter: 'Modifier le filtre',
                savefilter: 'Enregistrer les filtres',
                clearfilter: 'Effacer les filtres',
                select: 'Sélectionner',
                filled: 'Présent',
                notfilled: 'Absent',
                numval: 'Valeur numérique',
                datefrom: 'à partir du :',
                dateto: 'jusqu\'au :',
                agechild: 'L\'âge de l\'enfant',
                saveselected: 'Enregistrer les filtres sélectionnés',
                typelabel: 'Nom de la recherche',
                infosession: 'Session d\'information visitée',
                noinfosession: 'Session d\'information non visitée',
                none: 'Aucun',
                first: '1<sup>re</sup>',
                second: '2<sup>me</sup>',
                nonelong: 'Aucune session d\'information visitée',
                firstlong: '1<sup>re</sup> session d\'information visitée',
                secondlong: '1<sup>re</sup> et 2<sup>me</sup> sessions d\'information visitées',
                comingupin: 'Prévue dans…',
                expirein: 'Expire dans…',
                typenumber: 'Saisir un chiffre',
                arrivethismonth: 'Arrivé(e) ce mois-ci',
                arrivelastmonth: 'Arrivé(e) le mois dernier',
                withactivecovenant: 'Avec convention active',
                usetoday: 'Utilisez "aujourd\'hui"',
                today: 'aujourd\'hui',
                allownull: 'Inclure les champs vides',
                notsubmitted: 'Pas soumis',
                submitted: 'Soumis',
                notvisited1long: "N'a pas participé à la 1<sup>re</sup> session d'information malgré une deuxième invitation",
                notvisited2long: "N'a pas participé à la 2<sup>me</sup> session d'information malgré une deuxième invitation",
                notvisited1: 'Première session d\'information pas visité',
                notvisited2: 'Deuxième session d\'information pas visité',
                notassigned: 'Session d\'information non assignée',
                amount: 'Nombre de',
                period: 'Période',
                periods: {
                    days: 'Jour(s)',
                    months: 'Mois',
                },
                operator: 'Opérateur',
                operators: {
                    before: 'Avant',
                    after: 'Après',
                },
                dates: 'Date ou événement',
                dateStart: "Date d'arrivée de l'au-pair",
                dateEnd: "Date de départ de l'au-pair",
                dateEndEffective: "Fin effective du séjour de l'au-pair",
                dateRefusal: "Date de refus de l'au-pair",
                firstInfoSessionDate: "Date de la participation de l'au-pair à la session d'information",
            },
            stats: {
                origin: 'Au-pairs : pays d\'origine',
                stay: 'Au-pairs : période de séjour',
                arrival: 'Au-pairs : mois d\'arrivée',
                month: 'mois',
                year: '1 an +',
                agrement: 'Familles : nombre d\'agréments',
                approval: 'Familles : année d\'approbation',
                accepted: 'Accepté',
                refused: 'Refusé',
                total: 'Total',
                members: 'Familles : nombre de membres',
                member: 'membre | membres',
                refusal: 'Nombre d\'au-pairs refusés par an',
                age: 'Au-pairs regroupées par âge au moment de leur arrivée en {year}',
                avgAge: "Moyenne d'âge en {year} : {age}",
            },
            reasons: {
                new: 'Nouvel accord',
                control: 'Contrôle',
                other: 'Autre'
            },
            cetegories: {
                ap_pass: 'Passeport',
                ap_sch_cert: 'Certificat de scolarité',
                ap_med_cert: 'Certificat médical',
                ap_misc: 'Divers',
                fm_comp_cert: 'Certificat de composition',
                fm_req_form: 'Formulaire de demande',
                fm_misc: 'Divers'
            },
            settings: {
                templates: 'Modèle | Modèles',
                emailTemplates: 'Modèle de courriel | Modèles de courriel',
                actionScheduler: "Planificateur d'actions",
                schedules: {
                    active: 'Actif',
                    name: "Nom de l'action planifiée",
                    created: 'Créé',
                    modified: 'Modifié',
                    addnew: 'Ajouter une nouvelle action planifiée',
                    edit: "Modifier l'action planifiée",
                    delete: "Supprimer l'action planifiée",
                    deletesure: "Êtes-vous sûr de vouloir supprimer l'action planifiée <strong>{name}</strong> ?",
                    useEmailTemplate: 'En utilisant le modèle de courriel suivant :',
                    doThis: "Lorsque cette condition est remplie, exécuter l'action suivante :",
                    action: 'Action',
                    actions: {
                        email: 'Envoyer un courriel',
                    },
                    target: "Cible de l'action",
                    targets: {
                        aupair: 'Au-pair',
                    },
                    sent: 'Envoyé le',
                    includeFamilyMembers: "Envoyer également des e-mails aux membres de la famille d'accueil",
                    followup: 'Suivi',
                    complete: 'Achevé',
                    notcomplete: 'Inachevé',
                    requireConfirmation: 'Confirmation requise',
                    scheduleHistory: 'Historique des courriels envoyés',
                    note: "Toutes les actions s'appliqueront uniquement aux au-pairs dont la date de fin de séjour est prévue dans le futur.",
                },
                template: {
                    title: 'Gestion des modèles',
                    name: 'Nom du modèle',
                    edit: 'Modifier le modèle',
                    addnew: 'Ajouter un modèle',
                    created: 'Créé',
                    modified: 'Modifié',
                    delete: 'Supprimer le modèle',
                    deletesure: 'Êtes-vous sûr de vouloir supprimer le modèle <strong>{name}</strong> ?',
                    showid: 'Montrer le numéro de référence (ID)',
                    showaddress: 'Montrer l\'adresse',
                    showplace: 'Montrer le lieu et la date',
                    place: 'Endroit',
                    showsender: 'Montrer les informations sur l\'expéditeur',
                    sender: 'Expéditeur',
                    maincontent: 'Contenu principal',
                    showfooter: 'Montrer le pied de page',
                    defaultOnCreateAupair: "Envoyer automatiquement lors de la création du profil de l'au-pair",
                    defaultOnCreateFamily: 'Envoyer automatiquement lors de la création du profil de la famille',
                    isWordDocument: 'Modèle en format Word',
                    addWordTemplate: 'Téléverser un nouveau modèle en format Word',
                    replaceWordTemplate: 'Remplacer le modèle en format Word',
                    selectedFile: 'Fichier sélectionné',
                },
                emailTemplate: {
                    edit: 'Modifier le modèle de courriel',
                    addnew: 'Ajouter un nouveau modèle de courriel',
                    name: 'Nom du modèle de courriel',
                    subject: 'Objet du courriel',
                    body: 'Corps du courriel',
                    criteria: 'Critère de sélection',
                    attachment: 'Pièce jointe PDF',
                    '21 days': '21 jours',
                    '42 days': '42 jours',
                },
            },
            errors: {
                server: 'Erreur interne du serveur.',
                submit: 'Erreur lors de l\'envoi du formulaire. Faites défiler pour voir les champs en surbrillance.',
                download: 'Erreur lors du téléchargement du fichier.',
                delete: 'Erreur lors de la suppression du fichier.',
                upload: 'Erreur lors du chargement du fichier.',
                deletetemplate: 'Erreur lors de la suppression du modèle.',
                template: 'Le fichier Word contient des variables inconnues : {variable}',
            },
            availableVariables: 'Variables disponibles',
            variables: {
                current_date: 'Date actuelle',
                current_date_fr: 'Date actuelle (FR)',
                current_date_en: 'Date actuelle (EN)',
                aupair_name: "Nom de l'au-pair",
                aupair_id: "Identifiant de l'au-pair",
                aupair_title: "Titre de l'au-pair",
                aupair_duration: 'Durée du séjour',
                aupair_address: "Adresse de l'au-pair",
                family_id: 'Identifiant de la famille',
                family_name: 'Nom de la famille',
                family_address_multiline: 'Adresse de la famille',
                aupair_arrival_date_fr: "Date d'arrivée de l'au-pair (FR)",
                aupair_arrival_date_en: "Date d'arrivée de l'au-pair (EN)",
                arrival_date_plus_one_month_fr: "Date d'arrivée de l'au-pair +1 mois (FR)",
                arrival_date_plus_one_month_en: "Date d'arrivée de l'au-pair +1 mois (EN)",
                info_session_date_fr: "Date de la première session d'information \n à laquelle un au-pair a participé (FR)",
                info_session_date_en: "Date de la première session d'information \n à laquelle un au-pair a participé (EN)",
                family_address_line_1: 'Adresse de la famille (ligne 1)',
                family_address_line_2: 'Adresse de la famille (ligne 2)',
                ccss_document_is_missing: 'Certificat CCSS d\'affiliation est manquant',
                registration_certificate_is_missing: 'Déclaration de la commune est manquant',
                one_document_missing: 'Un document est manquant',
                two_documents_missing: 'Deux documents manquants',
            },
            and: 'et',
            stayMonths: '{months} mois | {months} mois',
            stayDays: '{days} jour | {days} jours'
        },
        en: {
            families: 'Family | Families',
            mrmrs: 'Mr./Mrs.',
            aupairs: 'Au-pair | Au-pairs',
            covenant: 'Covenant | Covenants',
            child: 'Child | Children',
            member: 'Member | Members',
            visit: 'Visit | Visits',
            infosession: 'Info-Session | Info-Sessions',
            note: 'Note | Notes',
            address: 'Address',
            date: 'Date',
            cancel: 'Cancel',
            confirm: 'Confirm',
            close: 'Close',
            save: 'Save',
            edit: 'Edit',
            delete: 'Delete',
            archive: 'Archive',
            ':': ':',
            noDataAvailable: 'No data available',
            rowsPerPage: 'Rows per page',
            years: '{years} years',
            toolbar: {
                dashboard: 'Dashboard',
                search: 'Search',
                statistics: 'Statistics',
                settings: 'Settings'
            },
            dashboard: {
                whatsimportant: 'What\'s important today?',
                withactivecovenant: 'Aupair with an active covenant | Aupairs with an active covenant',
                familyactive: 'Family with an active agreement | Families with an active agreement',
                covenant: 'Covenant is about to expire | Covenants are about to expire',
                aupairarriv: 'New aupair is due to arrive this month | New aupairs are due to arrive this month',
                aupairlastmonth: 'Aupair was scheduled to arrive the previous month | Aupairs were scheduled to arrive the previous month',
                infosession: 'Info-session is coming up | Info-sessions are coming up',
                days: 'today | within one day | within {count} days',
                deletequery: 'Delete query',
                deletequerysure: 'Are you sure you want to delete query',
                savedqueries: 'Saved queries',
                noqueries: 'No queries available',
                aupairs: 'Applies to au-pairs',
                families: 'Applies to families',
                covenants: 'Applies to covenants',
                visits: 'Applies to visits',
                infoSessions: 'Applies to info-sessions',
                seeall: 'See all',
                edit: 'Edit dashboard',
                save: 'Exit editing mode',
                remove: 'Remove this query from the dashboard',
                addquery: 'Add query',
                select: 'Select the query',
                notfirst: "Au-pairs who didn't participate in the first info-session",
                notsecond: "Au-pairs who didn't participate in the second info-session",
                aupairsWithUncheckedBoxes: 'Au-pairs with due documents',
                aupairsWithSentEmail: 'Au-pairs who have been sent a reminder letter/e-mail',
                aupairsFulfilling42DaysRule: "Au-pairs who didn't submit required documents after 42 days",
                dismiss: 'Done',
                dismissTitle: '42-days reminder letter',
                dismissText: 'Click on "Confirm" to mark this letter as sent.',
                sentDate: 'Sent on',
                schedulesAupairs: 'Applies to e-mails sent to au-pairs',
            },
            view: {
                age: 'Age',
                doc: {
                    documents: 'Documents',
                    drop: 'Drop file here or',
                    click: 'click to upload',
                    edit: 'Edit document label',
                    set: 'Set the label for file',
                    deletedoc: 'Delete document',
                    deletedocsure: 'Are you sure you want to delete document',
                    filename: 'Filename',
                    category: 'Category',
                    label: 'Label',
                    typetext: 'Type text here…',
                    nodocs: 'No documents…',
                    download: 'Click here to download the file'
                },
                note: {
                    author: 'Author',
                    label: 'Label',
                    description: 'Description',
                    addnote: 'Add note'
                },
                missingdate: 'Missing date',
                arrival: 'Arrival',
                infosession: '#1 info-session | #2 info-session',
                departure: 'Departure',
                letter: 'Letter',
                selecttemplate: 'Select template',
                generateletter: 'Generate Letter',
                firstinfosession: 'First info-session',
                secondinfosession: 'Second info-session',
                invitation: {
                    1: '1st invitation',
                    2: '2nd invitation',
                    3: '3rd invitation'
                }
            },
            form: {
                editaupair: 'Edit aupair',
                newaupair: 'Create new aupair',
                basic: 'Basic info',
                id: 'ID',
                nationality: 'Nationality',
                birth: 'Date of birth',
                age: 'Age',
                lastname: 'Lastname',
                firstname: 'Firstname',
                email: 'E-mail',
                pass: 'Passport number',
                passcopy: 'Copy of passport received',
                stay: 'Stay information',
                beginstay: 'Beginning of stay',
                endstay: 'Expected end of stay',
                endeff: 'Effective end date',
                noendeff: 'No effective end date',
                refused: 'Date of refusal',
                stayfor: 'Is staying for',
                days: 'day | days',
                approb: 'Has submitted approbation',
                medcert: 'Has medical certificate',
                scholar: 'Meets scholarity requirements',
                schoolcert: 'Has scholarity certificate',
                insurance: 'Insurance & social security',
                insurstart: 'Affiliation start date',
                insurend: 'Affiliation end date',
                matricule: 'Social Security Number',
                language: 'Language',
                languages: 'Languages',
                langspoken: 'Spoken languages',
                langcours: 'Language courses',
                addresscurr: 'Local address',
                street: 'Street',
                city: 'City',
                country: 'Country',
                addressorig: 'Address abroad',
                addcovenant: 'Add new covenant',
                scholarity: 'Meets scholarity requirements',
                daycare: 'Has daycare',
                startdate: 'Start date',
                enddate: 'End date',
                signed: 'Signed',
                notsigned: 'Not signed',
                editfamily: 'Edit family',
                newfamily: 'Create new family',
                name: 'Name',
                postcode: 'Postcode',
                addaddress: 'Add address row',
                agreement: 'Agreement',
                numagreem: 'Agreement number',
                approval: 'Approval date',
                withdrawal: 'Withdrawal date',
                submitted: 'Form submitted',
                composition: 'Composition certificate (< 3 months) received',
                addmember: 'Add new member',
                addchild: 'Add new child',
                addvisit: 'Add new visit',
                editinfosession: 'Edit Info-Session',
                newinfosession: 'Create new Info-Session',
                title: 'Title',
                location: 'Location',
                participants: 'Participants',
                participated: 'Participated',
                notparticipated: 'Not participated',
                confirmed: 'Presence confirmed',
                notconfirmed: 'Not confirmed',
                motive: 'Motive',
                familyrole: 'Family role',
                phone: 'Phone',
                occupation: 'Occupation',
                crimrecord: 'Has criminal record',
                noterecord: 'Criminal record note',
                datevisit: 'Date of visit',
                dateannounced: 'Date of announcement',
                announced: 'Announced',
                reason: 'Reason',
                result: 'Result',
                action: 'Action',
                from: 'From',
                until: 'Until',
                yes: 'Yes',
                no: 'No',
                nocovenants: 'No covenants available',
                noaupairs: 'No aupairs participated',
                deleteaupairs: 'Delete aupair',
                deletesureaupairs: 'Are you sure you want to delete au-pair',
                deletefamilies: 'Delete family',
                deletesurefamilies: 'Are you sure you want to delete family',
                deleteinfoSessions: 'Delete info-session',
                deletesureinfoSessions: 'Are you sure you want to delete info-session',
                archivefamily: 'Archive family',
                createnewfamily: 'Create a new family',
                previousfamily: 'Previous family',
                movecovenants: 'Move active covenants to the new family',
                copychildren: 'Copy children:',
                copymembers: 'Copy members:',
                familyisarchived: 'Family is archived',
                aboutarchive: 'You are about to archive the <strong>{family}</strong> family. The archived families are not visible in the family list.',
                noresults: 'No results',
                completed: 'Verified as complete',
                notcompleted: 'Not verified',
                mr: 'Mr.',
                mrs: 'Mrs.',
                overlapping: "Covenant dates can't overlap",
                infosessiontype: 'Type of info-session',
                ccsscert: 'Affiliation CCSS certificate submitted',
                sendinvitationmail: 'Send mail',
                unknown: 'N/A',
                insertplaceholder: 'Insert aupair/family placeholders…',
                insertsymbol: 'Insert special symbol…',
                checkbox: 'Checkbox (with check)',
                checkboxUnchecked: 'Checkbox (empty)',
                aupairname: 'Au-pair name',
                aupairid: 'Au-pair ID',
                aupairtitle: 'Au-pair title',
                familyname: 'Family name',
                familyid: 'Family ID',
                honordeclaration: 'Declaration on honor concerning family ties',
                weeklyschedule: 'Weekly schedule received',
                registrationcertificate: 'Registration certificate',
                certificateofresidence: 'Certificate of residence - of current domicile',
                checklist: 'Checklist'
            },
            list: {
                listaupairs: 'List of Aupairs',
                addaupair: 'Add new Aupair',
                search: 'Search',
                noinfosession: 'Has not participated in the first info-session | Has not participated in the second info-session',
                nosocsec: 'Social Security number not filled',
                from: 'From',
                to: 'to',
                of: 'of',
                To: 'To',
                all: 'All',
                listcovenants: 'List of Covenants',
                listfamilies: 'List of Families',
                addfamily: 'Add new Family',
                numcovenants: 'Number of covenants',
                numchildren: 'Number of children',
                nummembers: 'Number of members',
                listinfosessions: 'List of Info-Sessions',
                addinfosession: 'Add new Info-Sessions',
                listvisits: 'List of Visits',
                arrivalmonth: 'Arrival month',
                stayfor: 'Staying for (days)',
                addtemplate: 'Add new template',
                toggleselection: 'Toggle selection',
                showarchived: 'Show archived',
                labels: 'labels',
                type: 'Type',
                1: 'First',
                2: 'Second',
                invitation: 'Invitation',
                presence: 'Presence',
                addEmailTemplate: 'Add e-mail template',
            },
            filter: {
                add: 'Add filter',
                editfilter: 'Edit filter',
                savefilter: 'Save filters',
                clearfilter: 'Clear filters',
                select: 'Select',
                filled: 'Filled',
                notfilled: 'Not filled',
                numval: 'Numeric value',
                datefrom: 'Date from:',
                dateto: 'Date to:',
                agechild: 'Age of child',
                saveselected: 'Save this search for later',
                typelabel: 'Type a label',
                infosession: 'Info-session visited',
                noinfosession: 'Info-session not visited',
                none: 'None',
                first: '#1',
                second: '#2',
                nonelong: 'No info-session visited',
                firstlong: '#1 info-session visited',
                secondlong: '#1 and #2 info-session visited',
                comingupin: 'Coming up in…',
                expirein: 'Expires in…',
                typenumber: 'Type number',
                arrivethismonth: 'Arrives this month',
                arrivelastmonth: 'Arrived last month',
                withactivecovenant: 'With active covenant',
                usetoday: 'Use "today"',
                today: 'today',
                allownull: 'Include empty fields',
                notsubmitted: 'Not submitted',
                submitted: 'Submitted',
                notvisited1long: "Didn't attend the 1st information session despite a second invitation",
                notvisited2long: "Did'nt attend the 2nd information session despite a second invitation",
                notvisited: 'Info-session not visited after second invitation',
                notvisited1: '1st info-session not visited',
                notvisited2: '2nd info-session not visited',
                notassigned: 'Info-session not assigned',
                amount: 'Number of',
                period: 'Period',
                periods: {
                    days: 'Day(s)',
                    months: 'Month(s)',
                },
                operator: 'Operator',
                operators: {
                    before: 'Before',
                    after: 'After',
                },
                dates: 'Date or event',
                dateStart: "Date of au-pair's arrival",
                dateEnd: "Date of au-pair's departure",
                dateEndEffective: "Effective end of the au-pair's stay",
                dateRefusal: 'Refusal date of au-pair',
                firstInfoSessionDate: "Date of aupair's participation in info-session",
            },
            stats: {
                origin: 'Au-pairs: country of origin',
                stay: 'Au-pairs: stay period',
                arrival: 'Au-pairs: month of arrival',
                month: 'months',
                year: '1 year +',
                agrement: 'Families: number of agreements',
                approval: 'Families: year of approval',
                accepted: 'Accepted',
                refused: 'Refused',
                total: 'Total',
                members: 'Families: number of members',
                member: 'member | members',
                refusal: 'Number of aupairs refused per year',
                age: 'Au-pairs grouped by age at the time of their arrival in {year}',
                avgAge: 'Average age in {year}: {age}',
            },
            reasons: {
                new: 'New agreement',
                control: 'Control',
                other: 'Other'
            },
            cetegories: {
                ap_pass: 'Passport',
                ap_sch_cert: 'Scolarity certificate',
                ap_med_cert: 'Medical certificate',
                ap_misc: 'Miscellaneous',
                fm_comp_cert: 'Composition certificate',
                fm_req_form: 'Request form',
                fm_misc: 'Miscellaneous'
            },
            settings: {
                templates: 'Template | Templates',
                emailTemplates: 'E-mail template | E-mail templates',
                actionScheduler: 'Action scheduler',
                schedules: {
                    active: 'Active',
                    name: 'Schedule name',
                    created: 'Created',
                    modified: 'Modified',
                    addnew: 'Add new scheduled action',
                    edit: 'Edit scheduled action',
                    delete: 'Delete scheduled action',
                    deletesure: 'Are you sure you want to delete schedule <strong>{name}</strong> ?',
                    useEmailTemplate: 'Using this e-mail template',
                    doThis: 'When this condition is met, do this:',
                    action: 'Action',
                    actions: {
                        email: 'Send e-mail',
                    },
                    target: 'For a target',
                    targets: {
                        aupair: 'Au-pair',
                    },
                    sent: 'Sent on',
                    includeFamilyMembers: 'Send e-mail also to family members',
                    followup: 'Follow-up',
                    complete: 'Complete',
                    notcomplete: 'Not complete',
                    requireConfirmation: 'Require confirmation',
                    scheduleHistory: 'History of sent e-mails',
                    note: 'All actions will apply to au-pairs with an end-of-stay date in the future only',
                },
                template: {
                    title: 'Template management',
                    name: 'Template name',
                    edit: 'Edit template',
                    addnew: 'Add new template',
                    created: 'Created',
                    modified: 'Modified',
                    delete: 'Delete template',
                    deletesure: 'Are you sure you want to delete template <strong>{name}</strong> ?',
                    showid: 'Show Reference number (ID)',
                    showaddress: 'Show address',
                    showplace: 'Show place and date',
                    place: 'Place',
                    showsender: 'Show sender info',
                    sender: 'Sender',
                    maincontent: 'Main content',
                    showfooter: 'Show footer',
                    defaultOnCreateAupair: 'Send automatically when creating an aupair profile',
                    defaultOnCreateFamily: 'Send automatically when creating a family profile',
                    isWordDocument: 'Template is a Word document',
                    addWordTemplate: 'Upload new Word document template',
                    replaceWordTemplate: 'Replace Word document template',
                    selectedFile: 'Selected file',
                },
                emailTemplate: {
                    edit: 'Edit e-mail template',
                    addnew: 'Add new e-mail template',
                    name: 'E-mail template name',
                    subject: 'E-mail subject',
                    body: 'E-mail body',
                    criteria: 'Selection criterion',
                    attachment: 'PDF Attachment',
                    '21 days': '21 days',
                    '42 days': '42 days',
                },
            },
            errors: {
                server: 'Internal server error.',
                submit: 'Error while submitting form. Scroll up to see highlighted fields.',
                download: 'Error while downloading file.',
                delete: 'Error while deleting file.',
                upload: 'Error while uploading file.',
                deletetemplate: 'Error while deleting template.',
                template: 'The Word file contains unknown variables: {variable}',
            },
            availableVariables: 'Available variables',
            variables: {
                current_date: 'Current date',
                current_date_fr: 'Current date (FR)',
                current_date_en: 'Current date (EN)',
                aupair_name: 'Au-pair name',
                aupair_id: 'Au-pair ID',
                aupair_title: 'Au-pair title',
                aupair_duration: 'Is staying for',
                aupair_address: 'Au-pair address',
                family_id: 'Family ID',
                family_name: 'Family name',
                family_address_multiline: 'Family address',
                aupair_arrival_date_fr: 'Au-pair arrival date (FR)',
                aupair_arrival_date_en: 'Au-pair arrival date (EN)',
                arrival_date_plus_one_month_fr: 'Au-pair arrival date +1 month (FR)',
                arrival_date_plus_one_month_en: 'Au-pair arrival date +1 month (EN)',
                info_session_date_fr: 'Date of the first info-session \n an au-pair participated in (FR)',
                info_session_date_en: 'Date of the first info-session \n an au-pair participated in (EN)',
                family_address_line_1: 'Family address (line 1)',
                family_address_line_2: 'Family address (line 2)',
                ccss_document_is_missing: 'CCSS document is missing',
                registration_certificate_is_missing: 'Registration certificate is missing',
                one_document_missing: 'One document missing',
                two_documents_missing: 'Two documents missing',
            },
            and: 'and',
            stayMonths: '{months} month | {months} months',
            stayDays: '{days} day | {days} days'
        }
    }
};
