import { Mutations, QueryMutations } from '@/store/Mutations';
import { Actions } from '@/store/Actions';
export const mutations = {
    ...QueryMutations,
    ...Mutations
};
export const actions = {
    ...Actions
};
export const getters = {
    all: (state) => {
        return state.all;
    },
    activeQuery: (state) => {
        return state.activeQuery || (localStorage[state.name] ? JSON.parse(localStorage[state.name]) : undefined);
    },
};
export const state = {
    all: [],
    allPaginated: [],
    totalCount: 0,
    activeQuery: undefined,
    name: 'visits',
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
