import { Actions, DocumentActions, NoteActions, ExportActions, StatisticsActions, parachute } from '@/store/Actions';
import { Mutations, NoteMutations, CovenantMutations, QueryMutations } from '@/store/Mutations';
import { defaultFamily, defaultMember, defaultChild, defaultVisit } from '@/store/defaults';
import clonedeep from 'lodash.clonedeep';
import axios from '@/backend';
import { omit } from 'ramda';
export const mutations = {
    create(state) {
        console.log('create family');
        state.editing = clonedeep(defaultFamily);
    },
    addMember(state) {
        console.log('family add member');
        state.editing.members.push(clonedeep(defaultMember));
    },
    removeMember(state, { family, memberIndex }) {
        console.log('family remove member', memberIndex);
        if (memberIndex > -1) {
            family.members.splice(memberIndex, 1);
        }
    },
    addChild(state) {
        console.log('family add child');
        state.editing.children.push(clonedeep(defaultChild));
    },
    removeChild(state, { family, childIndex }) {
        console.log('family remove child', childIndex);
        if (childIndex > -1) {
            family.children.splice(childIndex, 1);
        }
    },
    addVisit(state) {
        console.log('family add visit');
        state.editing.visits.push(clonedeep(defaultVisit));
    },
    removeVisit(state, { family, visitIndex }) {
        console.log('family remove visit', visitIndex);
        if (visitIndex > -1) {
            family.visits.splice(visitIndex, 1);
        }
    },
    addAddress(state) {
        console.log('family add address');
        state.editing.address.push({ street: '', postcode: '', city: '' });
    },
    removeAddress(state, { family, addressIndex }) {
        console.log('family remove address', addressIndex);
        if (addressIndex > -1) {
            state.editing.address = family.address.filter((elem, index) => {
                return index !== addressIndex;
            });
        }
    },
    ...CovenantMutations,
    ...QueryMutations,
    ...NoteMutations,
    ...Mutations
};
export const actions = {
    archive: parachute(async ({ commit, state }, archiveInfo) => {
        console.log(`${state.name}: action archive`, archiveInfo);
        if (archiveInfo.createNewFamily) {
            const newFamily = clonedeep(omit(['children', 'members', 'visits', 'documents', 'covenants', 'notes'], state.editing));
            newFamily.children = archiveInfo.keepChildren;
            newFamily.members = archiveInfo.keepMembers;
            newFamily.uuid = undefined;
            newFamily.id = '';
            newFamily.oldFamilyId = state.editing.id;
            if (archiveInfo.moveActiveCovenant) {
                newFamily.covenants = state.editing.covenants.filter(c => !c.finished);
                state.editing.covenants = state.editing.covenants.filter(c => c.finished);
            }
            const response = await axios.post(`/api/${state.name}`, newFamily);
            commit('extend', response.data);
        }
        state.editing.archived = true;
        const itemUuid = state.editing.uuid;
        const response = await axios.put(`/api/${state.name}/${itemUuid}`, state.editing);
        commit('updateOne', response.data);
        commit('cancelEdit');
    }),
    ...StatisticsActions,
    ...DocumentActions,
    ...ExportActions,
    ...NoteActions,
    ...Actions
};
export const getters = {
    all: (state) => {
        return state.all;
    },
    allAvailable: (state) => {
        return state.allAvailable;
    },
    activeQuery: (state) => {
        return state.activeQuery || (localStorage[state.name] ? JSON.parse(localStorage[state.name]) : undefined);
    },
    editing: (state) => {
        return state.editing;
    },
    statistics: (state) => {
        return state.statistics;
    }
};
export const state = {
    all: [],
    allPaginated: [],
    totalCount: 0,
    allAvailable: {},
    activeQuery: undefined,
    name: 'families',
    editing: undefined,
    deleting: undefined,
    statistics: undefined,
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
