export default {
    en: {
        AD: 'Andorra',
        AE: 'United Arab Emirates',
        AF: 'Afghanistan',
        AG: 'Antigua and Barbuda',
        AI: 'Anguilla',
        AL: 'Albania',
        AM: 'Armenia',
        AO: 'Angola',
        AQ: 'Antarctica',
        AR: 'Argentina',
        AS: 'American Samoa',
        AT: 'Austria',
        AU: 'Australia',
        AW: 'Aruba',
        AX: 'Åland',
        AZ: 'Azerbaijan',
        BA: 'Bosnia and Herzegovina',
        BB: 'Barbados',
        BD: 'Bangladesh',
        BE: 'Belgium',
        BF: 'Burkina Faso',
        BG: 'Bulgaria',
        BH: 'Bahrain',
        BI: 'Burundi',
        BJ: 'Benin',
        BL: 'Saint Barthélemy',
        BM: 'Bermuda',
        BN: 'Brunei',
        BO: 'Bolivia',
        BQ: 'Bonaire',
        BR: 'Brazil',
        BS: 'The Bahamas',
        BT: 'Bhutan',
        BV: 'Bouvet Island',
        BW: 'Botswana',
        BY: 'Belarus',
        BZ: 'Belize',
        CA: 'Canada',
        CC: 'Cocos [Keeling] Islands',
        CD: 'Democratic Republic of the Congo',
        CF: 'Central African Republic',
        CG: 'Republic of the Congo',
        CH: 'Switzerland',
        CI: 'Ivory Coast',
        CK: 'Cook Islands',
        CL: 'Chile',
        CM: 'Cameroon',
        CN: 'China',
        CO: 'Colombia',
        CR: 'Costa Rica',
        CU: 'Cuba',
        CV: 'Cape Verde',
        CW: 'Curacao',
        CX: 'Christmas Island',
        CY: 'Cyprus',
        CZ: 'Czech Republic',
        DE: 'Germany',
        DJ: 'Djibouti',
        DK: 'Denmark',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        DZ: 'Algeria',
        EC: 'Ecuador',
        EE: 'Estonia',
        EG: 'Egypt',
        EH: 'Western Sahara',
        ER: 'Eritrea',
        ES: 'Spain',
        ET: 'Ethiopia',
        FI: 'Finland',
        FJ: 'Fiji',
        FK: 'Falkland Islands',
        FM: 'Federated States of Micronesia',
        FO: 'Faroe Islands',
        FR: 'France',
        GA: 'Gabon',
        GB: 'United Kingdom',
        GD: 'Grenada',
        GE: 'Georgia',
        GF: 'French Guiana',
        GG: 'Guernsey',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GL: 'Greenland',
        GM: 'The Gambia',
        GN: 'Guinea',
        GP: 'Guadeloupe',
        GQ: 'Equatorial Guinea',
        GR: 'Greece',
        GS: 'South Georgia and the South Sandwich Islands',
        GT: 'Guatemala',
        GU: 'Guam',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HK: 'Hong Kong',
        HM: 'Heard Island and McDonald Islands',
        HN: 'Honduras',
        HR: 'Croatia',
        HT: 'Haiti',
        HU: 'Hungary',
        ID: 'Indonesia',
        IE: 'Ireland',
        IL: 'Israel',
        IM: 'Isle of Man',
        IN: 'India',
        IO: 'British Indian Ocean Territory',
        IQ: 'Iraq',
        IR: 'Iran',
        IS: 'Iceland',
        IT: 'Italy',
        JE: 'Jersey',
        JM: 'Jamaica',
        JO: 'Jordan',
        JP: 'Japan',
        KE: 'Kenya',
        KG: 'Kyrgyzstan',
        KH: 'Cambodia',
        KI: 'Kiribati',
        KM: 'Comoros',
        KN: 'Saint Kitts and Nevis',
        KP: 'North Korea',
        KR: 'South Korea',
        KW: 'Kuwait',
        KY: 'Cayman Islands',
        KZ: 'Kazakhstan',
        LA: 'Laos',
        LB: 'Lebanon',
        LC: 'Saint Lucia',
        LI: 'Liechtenstein',
        LK: 'Sri Lanka',
        LR: 'Liberia',
        LS: 'Lesotho',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        LV: 'Latvia',
        LY: 'Libya',
        MA: 'Morocco',
        MC: 'Monaco',
        MD: 'Moldova',
        ME: 'Montenegro',
        MF: 'Saint Martin',
        MG: 'Madagascar',
        MH: 'Marshall Islands',
        MK: 'Republic of Macedonia',
        ML: 'Mali',
        MM: 'Myanmar [Burma]',
        MN: 'Mongolia',
        MO: 'Macau',
        MP: 'Northern Mariana Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MS: 'Montserrat',
        MT: 'Malta',
        MU: 'Mauritius',
        MV: 'Maldives',
        MW: 'Malawi',
        MX: 'Mexico',
        MY: 'Malaysia',
        MZ: 'Mozambique',
        NA: 'Namibia',
        NC: 'New Caledonia',
        NE: 'Niger',
        NF: 'Norfolk Island',
        NG: 'Nigeria',
        NI: 'Nicaragua',
        NL: 'Netherlands',
        NO: 'Norway',
        NP: 'Nepal',
        NR: 'Nauru',
        NU: 'Niue',
        NZ: 'New Zealand',
        OM: 'Oman',
        PA: 'Panama',
        PE: 'Peru',
        PF: 'French Polynesia',
        PG: 'Papua New Guinea',
        PH: 'Philippines',
        PK: 'Pakistan',
        PL: 'Poland',
        PM: 'Saint Pierre and Miquelon',
        PN: 'Pitcairn Islands',
        PR: 'Puerto Rico',
        PS: 'Palestine',
        PT: 'Portugal',
        PW: 'Palau',
        PY: 'Paraguay',
        QA: 'Qatar',
        RE: 'Réunion',
        RO: 'Romania',
        RS: 'Serbia',
        RU: 'Russia',
        RW: 'Rwanda',
        SA: 'Saudi Arabia',
        SB: 'Solomon Islands',
        SC: 'Seychelles',
        SD: 'Sudan',
        SE: 'Sweden',
        SG: 'Singapore',
        SH: 'Saint Helena',
        SI: 'Slovenia',
        SJ: 'Svalbard and Jan Mayen',
        SK: 'Slovakia',
        SL: 'Sierra Leone',
        SM: 'San Marino',
        SN: 'Senegal',
        SO: 'Somalia',
        SR: 'Suriname',
        SS: 'South Sudan',
        ST: 'São Tomé and Príncipe',
        SV: 'El Salvador',
        SX: 'Sint Maarten',
        SY: 'Syria',
        SZ: 'Swaziland',
        TC: 'Turks and Caicos Islands',
        TD: 'Chad',
        TF: 'French Southern Territories',
        TG: 'Togo',
        TH: 'Thailand',
        TJ: 'Tajikistan',
        TK: 'Tokelau',
        TL: 'East Timor',
        TM: 'Turkmenistan',
        TN: 'Tunisia',
        TO: 'Tonga',
        TR: 'Turkey',
        TT: 'Trinidad and Tobago',
        TV: 'Tuvalu',
        TW: 'Taiwan',
        TZ: 'Tanzania',
        UA: 'Ukraine',
        UG: 'Uganda',
        UM: 'U.S. Minor Outlying Islands',
        US: 'United States (USA)',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VA: 'Vatican City',
        VC: 'Saint Vincent and the Grenadines',
        VE: 'Venezuela',
        VG: 'British Virgin Islands',
        VI: 'U.S. Virgin Islands',
        VN: 'Vietnam',
        VU: 'Vanuatu',
        WF: 'Wallis and Futuna',
        WS: 'Samoa',
        XK: 'Kosovo',
        YE: 'Yemen',
        YT: 'Mayotte',
        ZA: 'South Africa',
        ZM: 'Zambia',
        ZW: 'Zimbabwe'
    },
    fr: {
        AD: 'Andorre',
        AE: 'Émirats arabes unis',
        AF: 'Afghanistan',
        AG: 'Antigua-et-Barbuda',
        AI: 'Anguilla',
        AL: 'Albanie',
        AM: 'Arménie',
        AO: 'Angola',
        AQ: 'Antarctique',
        AR: 'Argentine',
        AS: 'Samoa américaines',
        AT: 'Autriche',
        AU: 'Australie',
        AW: 'Aruba',
        AX: 'Åland',
        AZ: 'Azerbaïdjan',
        BA: 'Bosnie-Herzégovine',
        BB: 'Barbade',
        BD: 'Bangladesh',
        BE: 'Belgique',
        BF: 'Burkina Faso',
        BG: 'Bulgarie',
        BH: 'Bahreïn',
        BI: 'Burundi',
        BJ: 'Bénin',
        BL: 'Saint Barthélemy',
        BM: 'Bermudes',
        BN: 'Brunei',
        BO: 'Bolivie',
        BQ: 'Bonaire',
        BR: 'Brésil',
        BS: 'Bahamas',
        BT: 'Bhoutan',
        BV: 'Île Bouvet',
        BW: 'Botswana',
        BY: 'Biélorussie',
        BZ: 'Belize',
        CA: 'Canada',
        CC: 'Îles Cocos (Keeling)',
        CD: 'République démocratique du Congo',
        CF: 'République centrafricaine',
        CG: 'République du Congo',
        CH: 'Suisse',
        CI: "Côte d'Ivoire",
        CK: 'Îles Cook',
        CL: 'Chili',
        CM: 'Cameroun',
        CN: 'Chine',
        CO: 'Colombie',
        CR: 'Costa Rica',
        CU: 'Cuba',
        CV: 'Cap-Vert',
        CW: 'Curaçao',
        CX: 'Île Christmas',
        CY: 'Chypre',
        CZ: 'République tchèque',
        DE: 'Allemagne',
        DJ: 'Djibouti',
        DK: 'Danemark',
        DM: 'Dominique',
        DO: 'République dominicaine',
        DZ: 'Algérie',
        EC: 'Équateur',
        EE: 'Estonie',
        EG: 'Égypte',
        EH: 'Sahara occidental',
        ER: 'Érythrée',
        ES: 'Espagne',
        ET: 'Éthiopie',
        FI: 'Finlande',
        FJ: 'Fidji',
        FK: 'Îles Malouines',
        FM: 'Micronésie',
        FO: 'Îles Féroé',
        FR: 'France',
        GA: 'Gabon',
        GB: 'Royaume-Uni',
        GD: 'Grenade',
        GE: 'Géorgie',
        GF: 'Guyane française',
        GG: 'Guernesey',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GL: 'Groenland',
        GM: 'Gambie',
        GN: 'Guinée',
        GP: 'Guadeloupe',
        GQ: 'Guinée équatoriale',
        GR: 'Grèce',
        GS: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
        GT: 'Guatemala',
        GU: 'Guam',
        GW: 'Guinée-Bissau',
        GY: 'Guyana',
        HK: 'Hong Kong',
        HM: 'Îles Heard-et-MacDonald',
        HN: 'Honduras',
        HR: 'Croatie',
        HT: 'Haïti',
        HU: 'Hongrie',
        ID: 'Indonésie',
        IE: 'Irlande',
        IL: 'Israël',
        IM: 'Île de Man',
        IN: 'Inde',
        IO: "Territoire britannique de l'océan Indien",
        IQ: 'Irak',
        IR: 'Iran',
        IS: 'Islande',
        IT: 'Italie',
        JE: 'Jersey',
        JM: 'Jamaïque',
        JO: 'Jordanie',
        JP: 'Japon',
        KE: 'Kenya',
        KG: 'Kirghizistan',
        KH: 'Cambodge',
        KI: 'Kiribati',
        KM: 'Comores',
        KN: 'Saint-Christophe-et-Niévès',
        KP: 'Corée du Nord',
        KR: 'Corée du Sud',
        KW: 'Koweït',
        KY: 'Îles Caïmans',
        KZ: 'Kazakhstan',
        LA: 'Laos',
        LB: 'Liban',
        LC: 'Sainte-Lucie',
        LI: 'Liechtenstein',
        LK: 'Sri Lanka',
        LR: 'Liberia',
        LS: 'Lesotho',
        LT: 'Lituanie',
        LU: 'Luxembourg',
        LV: 'Lettonie',
        LY: 'Libye',
        MA: 'Maroc',
        MC: 'Monaco',
        MD: 'Moldavie',
        ME: 'Monténégro',
        MF: 'Saint Martin',
        MG: 'Madagascar',
        MH: 'Îles Marshall',
        MK: 'Macédoine',
        ML: 'Mali',
        MM: 'Myanmar [Birmanie]',
        MN: 'Mongolie',
        MO: 'Macao',
        MP: 'Îles Mariannes du Nord',
        MQ: 'Martinique',
        MR: 'Mauritanie',
        MS: 'Montserrat',
        MT: 'Malte',
        MU: 'Île Maurice',
        MV: 'Maldives',
        MW: 'Malawi',
        MX: 'Mexique',
        MY: 'Malaisie',
        MZ: 'Mozambique',
        NA: 'Namibie',
        NC: 'Nouvelle-Calédonie',
        NE: 'Niger',
        NF: 'Île Norfolk',
        NG: 'Nigeria',
        NI: 'Nicaragua',
        NL: 'Pays-Bas',
        NO: 'Norvège',
        NP: 'Népal',
        NR: 'Nauru',
        NU: 'Niue',
        NZ: 'Nouvelle-Zélande',
        OM: 'Oman',
        PA: 'Panama',
        PE: 'Pérou',
        PF: 'Polynésie française',
        PG: 'Papouasie-Nouvelle-Guinée',
        PH: 'Philippines',
        PK: 'Pakistan',
        PL: 'Pologne',
        PM: 'Saint-Pierre-et-Miquelon',
        PN: 'Îles Pitcairn',
        PR: 'Porto Rico',
        PS: 'Palestine',
        PT: 'Portugal',
        PW: 'Palaos',
        PY: 'Paraguay',
        QA: 'Qatar',
        RE: 'Réunion',
        RO: 'Roumanie',
        RS: 'Serbie',
        RU: 'Russie',
        RW: 'Rwanda',
        SA: 'Arabie Saoudite',
        SB: 'Îles Salomon',
        SC: 'Seychelles',
        SD: 'Soudan',
        SE: 'Suède',
        SG: 'Singapour',
        SH: 'Sainte-Hélène',
        SI: 'Slovénie',
        SJ: 'Svalbard et Jan Mayen',
        SK: 'Slovaquie',
        SL: 'Sierra Leone',
        SM: 'Saint-Marin',
        SN: 'Sénégal',
        SO: 'Somalie',
        SR: 'Suriname',
        SS: 'Soudan du Sud',
        ST: 'São Tomé-et-Príncipe',
        SV: 'Salvador',
        SX: 'Sint Maarten',
        SY: 'Syrie',
        SZ: 'Swaziland',
        TC: 'Îles Turques et Caïques',
        TD: 'Tchad',
        TF: 'Terres australes et antarctiques françaises',
        TG: 'Togo',
        TH: 'Thaïlande',
        TJ: 'Tadjikistan',
        TK: 'Tokelau',
        TL: 'Timor oriental',
        TM: 'Turkménistan',
        TN: 'Tunisie',
        TO: 'Tonga',
        TR: 'Turquie',
        TT: 'Trinité-et-Tobago',
        TV: 'Tuvalu',
        TW: 'Taïwan',
        TZ: 'Tanzanie',
        UA: 'Ukraine',
        UG: 'Ouganda',
        UM: 'Îles mineures éloignées des États-Unis',
        US: 'États-Unis',
        UY: 'Uruguay',
        UZ: 'Ouzbékistan',
        VA: 'Cité du Vatican',
        VC: 'Saint-Vincent-et-les-Grenadines',
        VE: 'Venezuela',
        VG: 'Îles Vierges britanniques',
        VI: 'Les iles vierges',
        VN: 'Viêt Nam',
        VU: 'Vanuatu',
        WF: 'Wallis-et-Futuna',
        WS: 'Samoa',
        XK: 'Kosovo',
        YE: 'Yémen',
        YT: 'Mayotte',
        ZA: 'Afrique du Sud',
        ZM: 'Zambie',
        ZW: 'Zimbabwe'
    }
};
